import React from 'react';
import {observable, action, computed} from 'mobx';
import EditUnitDialogBP from '../components/EditUnitDialogBP';

class UnitStore {
	@observable units;
	@observable unit;
	@observable isEditDialogOpen;
	terms = ['единица', 'единицы', 'единиц'];
	onCreateButtonTitle = "Новая единица измерения";
	titleField = "title";
	headers = [
		{ id: 'title', label: 'Название' },
		{ id: 'section.title', label: 'Раздел' },
	];
	defaultUnit = {
		id: 0,
		title: '',
		section: {
			id: 1,
			title: ''
		}
	};

	constructor(rootStore) {
		this.mainStore = rootStore.mainStore;
		this.units = [];
		this.unit = this.defaultUnit;
		this.isEditDialogOpen = false;
	}

	@action clear = () => {
		this.mainStore.setTitle('');
		this.units = [];
		this.unit = this.defaultUnit;
	};

	@action fetch = () => {
		this.mainStore.ajax('/api/units', {}, data => {
			this.units = data.units;
		});
	};

	@action create = () => {
		this.unit = this.defaultUnit;
		this.isEditDialogOpen = true;
	};

	@action read = (id) => {
		this.mainStore.ajax('/api/unit/' + id, {}, data => {
			this.unit = data.unit;
			this.isEditDialogOpen = true;
		});
	};

	@action update = () => {
		this.mainStore.ajax('/api/unit/update', this.unit, data => {
			this.isEditDialogOpen = false;
			this.fetch();
		});
	};

	@action delete = (id) => {
		this.mainStore.ajax('/api/unit/delete/' + id, {}, data => {
			this.fetch();
		});
	};

	@action edit = (id) => {
		this.read(id);
	};

	@action set = (field, value) => {
		//this.unit[field] = title;
		eval('this.unit.' + field + ' = value');
	};

	@computed get editDialogTitle() {
		return this.unit.id === 0 ? 'Новая единица измерения' : 'Изменение едницы измерения';
	};

	@action openEditDialog = () => this.isEditDialogOpen = true;
	@action closeEditDialog = () => this.isEditDialogOpen = false;

	@computed get items() { return this.units; }
	@computed get editDialog() { return <EditUnitDialogBP/>; }
}

export default UnitStore;
