import React from 'react';
import DefaultStore from './DefaultStore'
import {observable, action, computed} from 'mobx';
import apiMakeURL from "../utils/apiMakeURL";

class MessagesStore extends DefaultStore {
    @observable count = 0;
    @observable isFocus = false;
    timer = null;
    eventSource = null;

    constructor(rootStore) {
        super(rootStore);
        this.authStore = rootStore.authStore;
        this.fetchUnreadedCount();
        // this.timer = setInterval(this.fetchUnreadedCount, 60000);

        this.eventSource = new EventSource(apiMakeURL('/api/messages/unreaded/' + rootStore.authStore.user.id));
        this.eventSource.onmessage = (e) => {
            const item = JSON.parse(e.data);
            this.count = item.unreaded;
        }
    }

    @action fetchUnreadedCount = () => {
        if (this.authStore.isLogged) {
            this.mainStore.ajaxWithoutLoader('/api/messages/unreaded_count', {}, data => {
                if (this.count !== data.count) {
                    this.count = data.count;
                    if (this.isFocus) {
                        this.fetch();0
                    }
                }
            });
        }
    };

    @action fetch = () => this.mainStore.ajax('/api/messages', {}, data => {
        this.items = data.messages;
        // this.count = data.count;
        this.isFocus = true;
    });

    @action fetchItem = id => this.mainStore.ajax(`/api/message/${id}`, {}, data => {
        this.item = data.message;
        // this.count = data.count;
        const index = this.items.findIndex(i => i.id === data.message.id);
        if (index !== -1) {
            this.items[index].isReaded = true;
        }
    });

    @action clear = () => {
        this.isFocus = false;
        this.items = [];
        this.item = {};
    };

    @action closeMessage = () => this.item = {};
}

export default MessagesStore;