import React, { useState } from 'react';
import { inject, observer } from "mobx-react";
import {Button, MenuItem, Classes, ControlGroup, Dialog, FormGroup, HTMLSelect, TagInput} from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import highlightText from "../utils/highlightText";
import get from "lodash/get";

const SelectAddress = inject('peopleStore')(observer(({highlightText = null, peopleStore, ...props}) => {
	const handleSelectAddress = a => peopleStore.address = a.address;

	return (
		<Select
			items={peopleStore.suggestAddress}
			itemRenderer={(item, { handleClick, modifiers, query }) => (
				<MenuItem
					active={modifiers.active}
					disabled={modifiers.disabled}
					key={item.id + item.address}
					onClick={handleClick}
					text={highlightText(item.address, query)}
					multiline={true}
				/>
			)}
			onItemSelect={handleSelectAddress}
			inputProps={{placeholder: 'Введите адрес...', fill: true}}
			popoverProps={{fill: true, width: '100%'}}
			onQueryChange={peopleStore.findAddress}
		>
			<button type="button" className="bp3-button bp3-fill bp3-align-left" style={{minHeight: '30px', margin: 0}}>{peopleStore.address}</button>
		</Select>
	);
}));

const EditPeopleDialogBP = inject('peopleStore')(observer(({peopleStore}) => {
	const [isSearchOpen, setIsSearchOpen] = useState(false);

	const labelStyle = {
		width: '100px',
		flexShrink: 0,
		flexGrow: 0,
	};

	const groupStyle = {
		marginBottom: '2px'
	};

	return (
		<Dialog isOpen={peopleStore.isEditDialogOpen} onClose={peopleStore.closeEditDialog} title={peopleStore.editDialogTitle} usePortal={true} style={{width: '60%'}} canEscapeKeyClose={false} canOutsideClickClose={false}>
			<div className={Classes.DIALOG_BODY}>
				<FormGroup>
					<ControlGroup className="bp3-fill" style={groupStyle}>
						<label className="bp3-button" style={labelStyle} htmlFor="name">ФИО</label>
						<input className={Classes.INPUT + ' ' + 'bp3-fill'}
						       value={get(peopleStore.item, 'name', '')}
						       onChange={e => peopleStore.set('name', e.target.value)}
						       id='name'
						/>
					</ControlGroup>
				</FormGroup>

				<FormGroup>
					<ControlGroup className="bp3-fill" style={groupStyle}>
						<label className="bp3-button" style={labelStyle} htmlFor="phones">Телефоны</label>
						<TagInput fill={true}
						          values={peopleStore.item.phones.map(p => p.number.replace(/\D+/g, '').replace(/^.*(\d{3})(\d{3})(\d{2})(\d{2})$/, '+7 $1 $2-$3-$4'))}
						          onAdd={peopleStore.addPhone}
						          onRemove={peopleStore.removePhone}
						/>
					</ControlGroup>
				</FormGroup>

				<FormGroup>
					<ControlGroup className="bp3-fill" style={groupStyle}>
						<label className="bp3-button" style={labelStyle} htmlFor="emails">E-mail</label>
						<TagInput fill={true}
						          values={peopleStore.item.emails.map(e => e.email)}
						          onAdd={peopleStore.addEmail}
						          onRemove={peopleStore.removeEmail}
						/>
					</ControlGroup>
				</FormGroup>

				<FormGroup>
					<ControlGroup className="bp3-fill" style={groupStyle}>
						<label className="bp3-button" style={labelStyle} htmlFor="source">Источник</label>
						<HTMLSelect
							options={peopleStore.sources.map(s => ({value: s.id, label: s.title}))}
							value={get(peopleStore.item, 'source.id', '')}
							onChange={e => peopleStore.set('source.id', e.currentTarget.value)}
						/>
					</ControlGroup>
				</FormGroup>

				<FormGroup>
					<ControlGroup className="bp3-fill" style={groupStyle}>
						<label className="bp3-button" style={labelStyle} htmlFor="manager">Менеджер</label>
						<HTMLSelect
							options={peopleStore.managers.map(m => ({value: m.id, label: m.fio}))}
							value={get(peopleStore.item, 'manager.id', '')}
							onChange={e => peopleStore.set('manager.id', e.currentTarget.value)}
						/>
					</ControlGroup>
				</FormGroup>

				<ControlGroup className="bp3-fill" style={groupStyle}>
					<label className="bp3-button" style={labelStyle} htmlFor="manager">Создал</label>
					<input className={Classes.INPUT + ' ' + 'bp3-fill'}
						   value={get(peopleStore.item, 'creator.fio', '')}
						   readOnly={true}
					/>
				</ControlGroup>

				<div>
					<label className="bp3-label">Адреса:</label>
					{peopleStore.item.addresses.map((a, i) => {
						return (
							<div className="bp3-control-group" style={{marginBottom: '10px'}} key={i}>
								<div className="bp3-input-group bp3-fill">
									<input type="text" className="bp3-input" value={a.address} readOnly={true} />
									<div className="bp3-input-action">
										<button className="bp3-button bp3-minimal" onClick={() => peopleStore.removeAddress(i)}>
											<span className="bp3-icon-cross"/>
										</button>
									</div>
								</div>
							</div>
						);
					})}

					<div className="bp3-control-group" style={{marginBottom: '10px'}} key={'filter'}>
						<span className="bp3-button">Новый адрес</span>
						<div className="bp3-input-group bp3-fill">
							<SelectAddress highlightText={highlightText}/>
							<div className="bp3-input-action">
								<button className="bp3-button bp3-minimal" onClick={peopleStore.addAddress}>
									<span className="bp3-icon-add"/>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className={Classes.DIALOG_FOOTER}>
				<div className={Classes.DIALOG_FOOTER_ACTIONS}>
					<Button onClick={peopleStore.update} intent="success">Сохранить</Button>
					<Button onClick={peopleStore.closeEditDialog}>Закрыть</Button>
				</div>
			</div>
		</Dialog>
	);
}));

export default EditPeopleDialogBP;
