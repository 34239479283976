import React from 'react';
import {inject, observer} from "mobx-react";
import {
    Button,
    Classes,
    ControlGroup,
    Dialog,
    FormGroup, H5, MenuItem,
    TagInput
} from "@blueprintjs/core";
import { Suggest } from "@blueprintjs/select";
import styled from "styled-components";
import highlightText from "../utils/highlightText";
// import classNames from 'classnames';

const Label = styled.label`
    width: 150px;
    flex-shrink: 0 !important;
    flex-grow: 0 !important;
    display: flex;
    justify-content: flex-end;
`;

const EditAddressDialog = inject('addressStore')(observer(({addressStore}) => {
    if (addressStore.item.id === undefined) {
        return null;
    }

    return (
        <Dialog isOpen={addressStore.isEditDialogOpen} onClose={addressStore.closeEditDialog} title={addressStore.editDialogTitle} usePortal={true} style={{width: '60%'}} canEscapeKeyClose={false} canOutsideClickClose={false}>
            <div className={Classes.DIALOG_BODY}>
                {/*
                <FormGroup label={<Label>Адрес</Label>} inline contentClassName={Classes.FLEX_EXPANDER}>
                    <input type="text" className={classNames(Classes.INPUT, Classes.FILL)} value={addressStore.item.address} onChange={e => addressStore.set('address', e.target.value)} />
                </FormGroup>

                <FormGroup label={<Label>Площадь</Label>} inline contentClassName={Classes.FLEX_EXPANDER}>
                    <input type="text" className={classNames(Classes.INPUT, Classes.FILL)} value={addressStore.item.area} onChange={e => addressStore.set('area', e.target.value)} />
                </FormGroup>
                */}

                <FormGroup>
                    <ControlGroup className="bp3-fill">
                        <Label className="bp3-button" htmlFor="address">Адрес</Label>
                        <input id="address" className={Classes.INPUT + ' ' + 'bp3-fill'} value={addressStore.item.address} onChange={e => addressStore.set('address', e.target.value)} />
                    </ControlGroup>

                    <ControlGroup className="bp3-fill">
                        <Label className="bp3-button" htmlFor="area">Площадь</Label>
                        <input id="area" className={Classes.INPUT + ' ' + 'bp3-fill'} value={addressStore.item.area} onChange={e => addressStore.set('area', e.target.value)} />
                    </ControlGroup>

                    <ControlGroup className="bp3-fill">
                        <Label className="bp3-button" htmlFor="lat">Латитуда</Label>
                        <input id="lat" className={Classes.INPUT + ' ' + 'bp3-fill'} value={addressStore.item.lat} onChange={e => addressStore.set('lat', e.target.value)} />
                    </ControlGroup>

                    <ControlGroup className="bp3-fill">
                        <Label className="bp3-button" htmlFor="lng">Лонгитуда</Label>
                        <input id="lng" className={Classes.INPUT + ' ' + 'bp3-fill'} value={addressStore.item.lng} onChange={e => addressStore.set('lng', e.target.value)} />
                    </ControlGroup>

                    <ControlGroup className="bp3-fill">
                        <Label className="bp3-button" htmlFor="phones">Телефоны</Label>
                        <TagInput fill={true}
                                  values={addressStore.item.phones.map(p => p.replace(/\D+/g, '').replace(/^.*(\d{3})(\d{3})(\d{2})(\d{2})$/, '+7 $1 $2-$3-$4'))}
                                  onAdd={addressStore.addPhone}
                                  onRemove={addressStore.removePhone}
                                  addOnBlur={true}
                        />
                    </ControlGroup>

                    <ControlGroup className="bp3-fill">
                        <Label className="bp3-button" htmlFor="shopNumber">Магазин №</Label>
                        <input id="shopNumber" className={Classes.INPUT + ' ' + 'bp3-fill'} value={addressStore.item.shopNumber} onChange={e => addressStore.set('shopNumber', e.target.value)} />
                    </ControlGroup>

                    <ControlGroup className="bp3-fill">
                        <Label className="bp3-button" htmlFor="director">Директор</Label>
                        <input id="director" className={Classes.INPUT + ' ' + 'bp3-fill'} value={addressStore.item.director} onChange={e => addressStore.set('director', e.target.value)} />
                    </ControlGroup>

                    <ControlGroup className="bp3-fill">
                        <Label className="bp3-button" htmlFor="directorPhone">Телефон директора</Label>
                        {/*<input id="directorPhone" className={Classes.INPUT + ' ' + 'bp3-fill'} value={addressStore.item.directorPhone} onChange={e => addressStore.set('directorPhone', e.target.value)} />*/}
                        <TagInput fill={true}
                                  values={addressStore.item.directorPhone.map(p => p.replace(/\D+/g, '').replace(/^.*(\d{3})(\d{3})(\d{2})(\d{2})$/, '+7 $1 $2-$3-$4'))}
                                  onAdd={addressStore.addDirectorPhone}
                                  onRemove={addressStore.removeDirectorPhone}
                                  addOnBlur={true}
                        />
                    </ControlGroup>

                    <ControlGroup className="bp3-fill">
                        <Label className="bp3-button" htmlFor="comment">Комментарий</Label>
                        <input id="comment" className={Classes.INPUT + ' ' + 'bp3-fill'} value={addressStore.item.comment} onChange={e => addressStore.set('comment', e.target.value)} />
                    </ControlGroup>

                    <ControlGroup className="bp3-fill">
                        <Label className="bp3-button" htmlFor="cluster">Кластер</Label>
                        <input id="cluster" className={Classes.INPUT + ' ' + 'bp3-fill'} value={addressStore.item.cluster} onChange={e => addressStore.set('cluster', e.target.value)} />
                    </ControlGroup>
                </FormGroup>

                {addressStore.suggestions.length > 0 ? (
                    <div>
                        <H5>Найденные адреса:</H5>
                        {addressStore.suggestions.map((s, key) => (
                            <Button key={key} fill text={`${s.address} (${s.lat}:${s.lng})`} onClick={e => addressStore.setDadata(key)} />
                        ))}
                    </div>
                ) : null}
            </div>

            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button intent='danger' text='Проверить в дадата' onClick={addressStore.searchDadata}/>
                    <Button onClick={addressStore.update} intent="success">Сохранить</Button>
                    <Button onClick={addressStore.closeEditDialog}>Закрыть</Button>
                </div>
            </div>
        </Dialog>
    );
}));

export default EditAddressDialog;