import React from 'react';
import {Classes, Button, Dialog, ControlGroup, HTMLSelect} from "@blueprintjs/core";
import { inject, observer } from "mobx-react";

const EditUnitDialogBP = inject('unitStore')(observer((rootStore) => {
	const { unitStore } = rootStore;

	return (
		<Dialog isOpen={unitStore.isEditDialogOpen} onClose={unitStore.closeEditDialog} title={unitStore.editDialogTitle} usePortal={true} canEscapeKeyClose={false}>
			<div className={Classes.DIALOG_BODY}>
				{/*
				<Label>
					Название источника
					<input className={Classes.INPUT + ' ' + 'bp3-fill'} value={unitStore.source.title} onChange={e => unitStore.set('title', e.target.value)} />
				</Label>
				*/}
				<ControlGroup className="bp3-fill">
					<HTMLSelect value={unitStore.unit.section.id}
					            options={[
						            {value: 1, label: 'Материалы'},
						            {value: 2, label: 'Услуги'}
					            ]}
					            onChange={e => unitStore.set('section.id', e.currentTarget.value)}
					            minimal={false}
					            style={{width: '130px'}}
					/>

					<input className={Classes.INPUT + ' ' + 'bp3-fill'}
					       value={unitStore.unit.title}
					       onChange={e => unitStore.set('title', e.target.value)}
					/>
				</ControlGroup>
			</div>

			<div className={Classes.DIALOG_FOOTER}>
				<div className={Classes.DIALOG_FOOTER_ACTIONS}>
					<Button onClick={unitStore.update} intent="success">Сохранить</Button>
					<Button onClick={unitStore.closeEditDialog}>Закрыть</Button>
				</div>
			</div>
		</Dialog>
	);
}));

export default EditUnitDialogBP;