import React, { useEffect, Suspense, lazy } from 'react';
import { observer, inject } from "mobx-react";
import {Position, Toaster} from "@blueprintjs/core";
const SignIn = lazy(() => import('./SignIn'));
const DashboardBP = lazy(() => import('./DashboardBP'));

const ErrorToaster = Toaster.create({
	className: 'errors_toaster',
	position: Position.TOP,
});

Date.prototype.toLocaleFormat = function (format) {
	const months = [
		'января',
		'февраля',
		'марта',
		'апреля',
		'мая',
		'июня',
		'июля',
		'августа',
		'сентября',
		'октября',
		'ноября',
		'декабря'
	];
	const f = {
		Y: this.getFullYear(),
		y: this.getFullYear() - (this.getFullYear() >= 2e3 ? 2e3 : 1900),
		m: this.getMonth() + 1,
		j: months[this.getMonth()],
		d: this.getDate(),
		H: this.getHours(),
		// H: this.getHours() < 10 ? `0${this.getHours()}` : this.getHours(),
		M: this.getMinutes(),
		// M: this.getMinutes() < 10 ? `0${this.getMinutes()}` : this.getMinutes(),
		S: this.getSeconds()
	};
	for (let k in f)
		format = format.replace('%' + k, f[k] < 10 ? "0" + f[k] : f[k]);
	return format;
};

const App = inject('authStore', 'mainStore')(observer(({authStore, mainStore}) => {
	useEffect(() => {
		mainStore.setToaster(ErrorToaster);
	}, []);

	return (
		<Suspense fallback={<div/>}>
			{ authStore.isLogged ? <DashboardBP /> : <SignIn /> }
		</Suspense>
	);
}));

export default App;
