import React from 'react';

export default class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isError: false,
			error: ''
		};
	}

	static getDerivedStateFromError(error) {
		// console.log(Object.getOwnPropertyNames(error));
		// console.log('name', error.name);
		// console.log('message', error.message);
		// console.log('stack', error.stack.toString());
		// console.log('fileName', error.fileName);
		// console.log('lineNumber', error.lineNumber);
		return {
			isError: true,
			error: error
		};
	}

	componentDidCatch(error, errorInfo) {
		// console.log('catch', error, errorInfo)
	}

	render() {
		if (this.state.isError) {
			return <pre>{this.state.error.stack.toString()}</pre>;
		}

		return this.props.children;
	}
}
