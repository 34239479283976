import React from 'react';
import DefaultStore from './DefaultStore'
import {observable, action, computed} from 'mobx';
import EditContractDialog from "../components/EditContractDialog";
import set from 'lodash/set'

class ContractStore extends DefaultStore {
	defaultItem = {
		id: 0,
		type: {
			id: 0,
			title: ''
		},
		status: {
			id: 0,
			title: ''
		},
		info: '',
		number: '',
		orders: [],
		months: 12,
		startedAt: new Date(),
		org: {}
	};
	@observable orgs = [];
	@observable peoples = [];
	terms = ['договор', 'договора', 'договоров'];
	onCreateButtonTitle = "Новый договор";
	titleField = "number";
	headers = [
		{id: 'number', label: 'Номер', sort: i => i.number.toLowerCase().trim()},
		{id: 'type.title', label: 'Тип', sort: i => i.type.title.toLowerCase().trim()},
		// {id: 'org.name', label: 'Клиент', sort: i => i.org.name.toLowerCase().trim()},
		{id: c => {
				if (c.org !== null) return c.org.name;
				if (c.people !== null) return c.people.name;
				return '';
			}, label: 'Клиент', sort: i => {
				if (c.org !== null) return i.org.name.toLowerCase().trim();
				if (c.people !== null) return i.people.name.toLowerCase().trim();
				return '';
			}
		},
		{id: c => Boolean(new Date(c.startedAt).getTime()) ? new Date(c.startedAt).toLocaleFormat("%d.%m.%Y") : '', label: 'Дата начала', sort: i => new Date(i.startedAt)},
		{id: 'months', label: 'Длительность', sort: i => i.months},
		{id: 'status.title', label: 'Статус', sort: i => i.status.title.toLowerCase().trim()},
		{id: 'createdBy.fio', label: 'Создал', sort: i => i.createdBy.fio.toLowerCase().trim()}
	];
	@observable types = [];
	@observable statuses = [];
	timer = null;

	@computed get editDialogTitle() { return this.item.id === 0 ? 'Новый договор' : 'Изменение договора'; }
	@computed get editDialog() { return <EditContractDialog />; }

	@action fetch = () => this.mainStore.ajax('/api/contracts', {}, data => {
		this.types = data.types;
		this.statuses = data.statuses;
		if (data.types.length > 0) {
			this.defaultItem.type = data.types.find(t => t.id === 5);
		}
		if (data.statuses.length > 0) {
			this.defaultItem.status = data.statuses.find(s => s.id === 1);
		}
		this.items = data.contracts;
	});

	@action fetchItem = id => this.mainStore.ajax(`/api/contract/${id}`, {}, data => {
		this.item = data.contract;
		this.openEditDialog();
	});

	@action clear = () => {
		this.items = [];
		this.item = {};
		this.orgs = [];
	};

	@action create = () => {
		this.mainStore.ajax(`/api/contract/get_number/${this.defaultItem.type.id}`, {}, data => {
			this.item = {...this.defaultItem, number: data.number};
			this.openEditDialog();
		});
	};

	@action update = () => this.mainStore.ajax(`/api/contract/update/${this.item.id}`, {...this.item}, () => {
		this.orgs = [];
		this.closeEditDialog();
		this.fetch();
	});

	@action delete = id => this.mainStore.ajax(`/api/contract/delete/${id}`, {}, () => this.fetch());
	@action edit = id => this.fetchItem(id);
	@action setOrg = index => this.item.org = this.orgs[index];

	@action findOrgs = needle => {
		if (needle.length >= 2) {
			this.orgs = [];
			clearTimeout(this.timer);
			this.timer = setTimeout(() => {
				this.mainStore.ajax('/api/clients/search', {isPeople: false, needle: needle}, data => this.orgs = data.orgs);

				/*
				this.mainStore.startLoading();
				fetch('https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party', {
					method: 'POST',
					mode: 'cors',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': 'Token ' + this.mainStore.dadataToken
					},
					body: JSON.stringify({
						query: needle,
						count: 10
					})
				})
					.then(res => res.json())
					.then(data => {
						this.orgs.concat(data.suggestions);
					})
					.catch(alert)
					.finally(() => {
						this.mainStore.stopLoading();
					});

				 */
			}, 500);
		}
	};

	@action findPeople = needle => {
		if (needle.length >= 2) {
			this.peoples = [];
			clearTimeout(this.timer);
			this.timer = setTimeout(() => {
				this.mainStore.ajax('/api/clients/search', {isPeople: true, needle: needle}, data => this.peoples = data.peoples);
			}, 500);
		}
	};

	@action setType = e => {
		set(this.item, 'type.id', parseInt(e.target.value));
		this.mainStore.ajax(`/api/contract/get_number/${e.target.value}`, {}, data => {
			set(this.item, 'number', data.number);
		});
	};
}

export default ContractStore;