import React from 'react';
import styled, { keyframes } from 'styled-components';
import { observer, inject } from "mobx-react";

const duration = '1.5s';
const containerSize = '250px';
const boxSize = '33px';
const boxBorderRadius = '15%';

const slide = keyframes`
	0% { background-color: #1795FF; transform: translatex(0vw); }
	100% { background-color: #23D3FB; transform: translatex(calc(${containerSize} - ${boxSize} * 1.25)); }
`;

const colorChange = keyframes`
	0% { background-color: #1795FF; }
	100% { background-color: #23D3FB; }
`;

const flip1 = keyframes`
	0%, 15% { transform: rotate(0); }
	35%, 100% { transform: rotate(-180deg); }
`;

const squidge1 = keyframes`
	5% { transform-origin: center bottom; transform: scalex(1) scaley(1); }
	15% { transform-origin: center bottom; transform: scalex(1.3) scaley(0.7); }
	25%, 20% { transform-origin: center bottom; transform: scalex(0.8) scaley(1.4); }
	55%, 100% { transform-origin: center top; transform: scalex(1) scaley(1); }
	40% { transform-origin: center top; transform: scalex(1.3) scaley(0.7); }
`;

const flip2 = keyframes`
	0%, 30% { transform: rotate(0); }
	50%, 100% { transform: rotate(-180deg); }
`;

const squidge2 = keyframes`
	20% { transform-origin: center bottom; transform: scalex(1) scaley(1); }
	30% { transform-origin: center bottom; transform: scalex(1.3) scaley(0.7); }
	40%, 35% { transform-origin: center bottom; transform: scalex(0.8) scaley(1.4); }
	70%, 100% { transform-origin: center top; transform: scalex(1) scaley(1); }
	55% { transform-origin: center top; transform: scalex(1.3) scaley(0.7); }
`;

const flip3 = keyframes`
	0%, 45% { transform: rotate(0); }
	65%, 100% { transform: rotate(-180deg); }
`;

const squidge3 = keyframes`
	35% { transform-origin: center bottom; transform: scalex(1) scaley(1); }
	45% { transform-origin: center bottom; transform: scalex(1.3) scaley(0.7); }
	55%, 50% { transform-origin: center bottom; transform: scalex(0.8) scaley(1.4); }
	85%, 100% { transform-origin: center top; transform: scalex(1) scaley(1); }
	70% { transform-origin: center top; transform: scalex(1.3) scaley(0.7); }
`;

const flip4 = keyframes`
	0%, 60% { transform: rotate(0); }
	80%, 100% { transform: rotate(-180deg); }
`;

const squidge4 = keyframes`
	50% { transform-origin: center bottom; transform: scalex(1) scaley(1); }
	60% { transform-origin: center bottom; transform: scalex(1.3) scaley(0.7); }
	70%, 65% { transform-origin: center bottom; transform: scalex(0.8) scaley(1.4); }
	100%, 100% { transform-origin: center top; transform: scalex(1) scaley(1); }
	85% { transform-origin: center top; transform: scalex(1.3) scaley(0.7); }
`;

const PreloaderContainer = styled.div`
	position: fixed;
	z-index: 999999;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.5);
	justify-content: center;
	align-items: center;
	display: flex;
	transition: all .2s ease-in-out;
	visibility: ${props => props.isLoading ? 'visible' : 'hidden'};
	opacity: ${props => props.isLoading ? 1 : 0};
	
	& .preloader {
		width: ${containerSize};
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
		
		& > div {
			width: ${boxSize};
			height: ${boxSize};
			position: relative;
			display: block;
			transform-origin: -50% center;
			border-radius: ${boxBorderRadius};
			
			&:after {
				content: '';
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				right: 0;
				background-color: lightblue;
				border-radius: ${boxBorderRadius};
				box-shadow: 0 0 10px 0 rgba(28, 159, 255, 0.4);
			}
			
			&:nth-child(1) { 
				animation: ${slide} ${duration} ease-in-out infinite alternate;
				
				&:after { animation: ${colorChange} ${duration} ease-in-out infinite alternate; } 
			}
			
			&:nth-child(2) { 
				animation: ${flip1} ${duration} ease-in-out infinite alternate;
				
				&:after { 
					animation: ${squidge1} ${duration} ease-in-out infinite alternate;
					background-color: #1C9FFF; 
				} 
			}
			
			&:nth-child(3) { 
				animation: ${flip2} ${duration} ease-in-out infinite alternate;
				
				&:after {
					animation: ${squidge2} ${duration} ease-in-out infinite alternate;
					background-color: #1FB1FD;
				} 
			}
			
			&:nth-child(4) { 
				animation: ${flip3} ${duration} ease-in-out infinite alternate;
				
				&:after {
					animation: ${squidge3} ${duration} ease-in-out infinite alternate;
					background-color: #22C7FB;
				} 
			}
			
			&:nth-child(5) { 
				animation: ${flip4} ${duration} ease-in-out infinite alternate;
				
				&:after {
					animation: ${squidge4} ${duration} ease-in-out infinite alternate;
					background-color: #23D3FB;
				} 
			}
		}
	}
`;

export const Preloader = inject('mainStore')(observer(({mainStore}) => {
	return (
		<PreloaderContainer isLoading={mainStore.isLoading}>
			<div className="preloader">
				<div/>
				<div/>
				<div/>
				<div/>
				<div/>
			</div>
		</PreloaderContainer>
	);
}));
