import React from 'react';
import {observable, action} from 'mobx';
import set from 'lodash/set'

class ExecutorStore {
	@observable items = [];
	@observable item = {};
	@observable services = [];
	@observable extraServices = [];
	@observable workTime = '';
	@observable materials = [];
	timer = null;
	@observable result = this.defaultResult = {
		id: 0,
		forOrder: {
			id: 0
		},
		guarantee: 0,
		rePrice: 0,
		recomendations: '',
		comment: '',
		materials: []
	};

	@observable fetchStatus = 'all';

	constructor(rootStore) {
		this.mainStore = rootStore.mainStore;
		if (localStorage.getItem('fetchStatus') === null) {
			localStorage.setItem('fetchStatus', 'all');
		} else {
			this.fetchStatus = localStorage.getItem('fetchStatus');
		}
	}

	@action fetchItems = () => this.mainStore.ajax('/api/executor', {status: this.fetchStatus}, data => {
		this.items = data.orders;
	});

	@action workTimeFunc = () => {
		const startDate = new Date(this.item.startedAt);
		const now = new Date();
		const interval = now.getTime() - startDate.getTime();
		const secs = interval / 1000;
		const mins = Math.floor(secs / 60);
		const hours = Math.floor(secs / 3600);
		this.workTime = `${hours < 10 ? `0${hours}` : hours}:${mins < 10 ? `0${mins}` : mins}`;
	};

	@action fetchItem = id => this.mainStore.ajax(`/api/executor/order/${id}`, {}, data => {
		clearInterval(this.timer);
		this.services = data.services;
		this.extraServices = data.extraServices;
		let phones = [];
		try {
			phones = JSON.parse(data.order.address.phones);
		} catch (e) {

		}
		set(data, 'order.address.phones', phones);
		this.item = {
			...data.order,
			services: JSON.parse(data.order.services),
			extraServices: JSON.parse(data.order.extraServices)
		};
		if (data.order.status.id === 3) {
			this.workTimeFunc();
			this.timer = setInterval(this.workTimeFunc, 60000);
		}

		if (!Boolean(data.order.result)) {
			this.result = this.defaultResult;
			this.result.forOrder.id = data.order.id;
		} else {
			this.result = data.order.result;
		}
		this.materials = data.materials;
	});

	@action clear = () => {
		this.mainStore.setBreadcrumbs([]);
		this.items = [];
		this.item = {};
		this.result = {};
	};

	@action accept = () => this.mainStore.ajax(`/api/executor/accept/${this.item.id}`, {}, () => this.fetchItem(this.item.id));
	@action start = () => this.mainStore.ajax(`/api/executor/start/${this.item.id}`, {}, () => this.fetchItem(this.item.id));
	@action stop = () => this.mainStore.ajax(`/api/executor/stop/${this.item.id}`, {}, () => this.fetchItem(this.item.id));
	@action pay = () => this.mainStore.ajax(`/api/executor/pay/${this.item.id}`, {}, () => this.fetchItem(this.item.id));
	@action check = (destination, lines, sum, callback) =>
		this.mainStore.ajax(`/api/executor/check/${this.item.id}`, {
			destination: destination,
			lines: lines,
			sum: sum
		}, () => {
			this.mainStore.addMessage('Чек отправлен клиенту');
			callback();
			this.fetchItem(this.item.id)
		});

	@action setResult = (name, value) => eval(`this.result.${name} = value;`);

	@action addMaterial = () => {
		const materials = this.getMaterials();

		if (materials.length > 0) {
			this.result.materials.push({
				id: materials[0].id,
				count: 0
			});
		}
	};

	@action deleteMaterial = index => this.result.materials.splice(index, 1);

	@action getMaterials = () => {
		const ids = this.result.materials.map(m => m.id);
		return this.materials.filter(m => !ids.includes(m.id));
	};

	@action setMaterial = (index, id) => this.result.materials[index].id = id;
	@action setCount = (index, e) => this.result.materials[index].count = e.target.value.replace(',', '.').replace(/[^0-9\.]/g, '');

	@action fetchMaterials = () => this.mainStore.ajax('/api/materials', {}, data => this.materials = data.materials);

	@action updateResult = () => this.mainStore.ajax('/api/executor/result/update', {...this.result}, data => this.fetchItem(data.id));

	@action setFetchStatus = e => {
		this.fetchStatus = e.target.value;
		localStorage.setItem('fetchStatus', e.target.value);
		this.fetchItems();
	};

	@action uploadFiles = e => {
		const files = e.target.files;
		if (files.length > 0) {
			const data = new FormData();
			for (let i = 0; i < files.length; i++) {
				data.append(`file${i}`, files[i]);
			}
			this.mainStore.ajax(`/api/executor/upload/${this.result.id}`, data, d => this.item.files = d.files);
		}
	};

	@action deleteFile = (id, name) => {
		this.mainStore.ajax(`/api/executor/image/delete`, {id: id, image: name}, d => this.item.files = d.files);
	};

	@action call = (phone, callback) => this.mainStore.ajax(`/api/executor/call`, {phone: phone}, callback);
}

export default ExecutorStore;