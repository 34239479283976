import {observable, action, computed} from 'mobx';
import axios from "axios";

Date.prototype.getDaysInMonth = function() {
	return (new Date(this.getFullYear(), this.getMonth() + 1, 0)).getDate();
};

Date.prototype.getFirstDayOfMonth = function() {
	let temp = new Date(this.getFullYear(), this.getMonth(), 1).getDay();
	return temp === 0 ? 7 : temp;
};

class CalendarStore {
	months = [
		['январь', 'января'],
		['февраль', 'февраля'],
		['март', 'марта'],
		['апрель', 'апреля'],
		['май', 'мая'],
		['июнь', 'июня'],
		['июль', 'июля'],
		['август', 'августа'],
		['сентябрь', 'сентября'],
		['октябрь', 'октября'],
		['ноябрь', 'ноября'],
		['декабрь', 'декабря'],
	];
	last = '';
	@observable month = 0;
	@observable year = 0;
	@observable orders = [];
	@observable ordersByMonths = [];
	@observable days = 0;
	@observable firstDay = 0;
	@observable weeks = 0;
	@observable isLoaded = false;
	@observable services = [];
	@observable extraServices = [];
	@observable filters = {
		executor: 0,
		manager: 0,
		address: '',
		client: '',
		service: 0,
		status: 0,
		withoutX5: true,
	};
	@observable executors = [];
	@observable managers = [];
	@observable statuses = [];

	timer = null;

	constructor(rootStore) {
		this.mainStore = rootStore.mainStore;
	}

	@computed get items() {
		return this.orders
			.filter(i => this.filters.status === 0 || i.status.id === this.filters.status)
			.filter(i => this.filters.executor === 0 || i.executors.findIndex(e => e.id === this.filters.executor) !== -1)
			.filter(i => this.filters.manager === 0 || (i.creator !== null && i.creator.id === this.filters.manager))
			.filter(i => this.filters.service === 0 || i.services.findIndex(s => s.id === this.filters.service) !== -1)
			.filter(i => this.filters.address.trim() === '' || (i.address !== null && i.address.address.toLowerCase().includes(this.filters.address.trim().toLowerCase())))
			.filter(i => this.filters.client.trim() === '' ||
				(i.org !== null && i.org.name.toLowerCase().includes(this.filters.client.trim().toLowerCase())) ||
				(i.org !== null && i.org.client !== null && i.org.client.title.toLowerCase().includes(this.filters.client.trim().toLowerCase())) ||
				(this.filters.client.trim().replace(/\D+/g, '').length >= 4 && i.org !== null && i.org.phones.length > 0 && i.org.phones.findIndex(p => p.number.includes(this.filters.client.trim().replace(/\D+/g, ''))) !== -1) ||
				(i.people !== null && i.people.name.toLowerCase().includes(this.filters.client.trim().toLowerCase())) ||
				(this.filters.client.trim().replace(/\D+/g, '').length >= 4 && i.people !== null && i.people.phones.length > 0 && i.people.phones.findIndex(p => p.number.includes(this.filters.client.trim().replace(/\D+/g, ''))) !== -1)
			)
			/*
			.filter(
				i => this.filters.withoutX5 === false ||
					i.org === null ||
					i.org.client === null ||
					i.org.client.title.toLowerCase().includes('x5 retail') === false ||
					i.services.findIndex(s => s.id === 5 || s.id === 6) === -1
			)
			 */
			.filter(
				i => this.filters.withoutX5 === true || !i.isMass
			)
	}

	@action clear() {
		clearInterval(this.timer);
		this.orders = [];
		this.ordersByMonths = [];
		this.last = '';
		this.month = 0;
		this.year = 0;
		this.days = 0;
		this.firstDay = 0;
		this.isLoaded = false;
		this.executors = [];
	}

	@action fetch = (month = null, year = null) => {
		this.mainStore.ajax('/api/calendar/2020/9', this.getPeriod(9, 2020));

		clearInterval(this.timer);

		if (month === null && parseInt(this.month) > 0) {
			month = this.month;
		}

		if (year === null && parseInt(this.year) > 0) {
			year = this.year;
		}

		//this.orders = [];
		const url = year !== null && month !== null ? `/api/calendar/${year}/${month}` : '/api/calendar';
		this.mainStore.ajax(url, {}, (data) => {
			this.executors = data.executors.sort((a, b) => {
				if (a.enabled === b.enabled) return 0;
				return a.enabled > b.enabled ? -1 : 1;
			});
			this.managers = data.managers.sort((a, b) => {
				if (a.enabled === b.enabled) return 0;
				return a.enabled > b.enabled ? -1 : 1;
			});
			this.statuses = data.statuses;
			const date = new Date(`${data.year}-${data.month}-01`);
			this.days = date.getDaysInMonth();
			this.firstDay = date.getFirstDayOfMonth();
			this.weeks = Math.ceil((this.firstDay + this.days - 1) / 7);

			const orders = [];

			if (data.services !== null) {
				this.services = data.services;
			}

			if (data.extraServices !== null) {
				this.extraServices = data.extraServices;
			}

			this.month = parseInt(data.month);
			this.year = parseInt(data.year);
			this.last = data.last;

			data.orders.forEach(o => {
				o.dtWork = new Date(o.dtWork);

				try {
					o.services = JSON.parse(o.services);
				} catch (e) {
					o.services = [];
				}

				try {
					o.extraServices = JSON.parse(o.extraServices);
				} catch (e) {
					o.extraServices = [];
				}

				orders.push(o);
			});

			this.timer = setInterval(this.getUpdates, 60000);
			this.isLoaded = true;
			this.orders = orders;
		});
	};

	getUpdates = () => {
		axios.post('/api/calendar/updates', {month: this.month, year: this.year, last: this.last})
			.then(data => data.data)
			.then(data => {
				if (data.success || data.token !== undefined) {
					if (this.last !== data.last) {
						this.last = data.last;
					}
					if (JSON.stringify(this.ordersByMonths) !== JSON.stringify(data.ordersByMonths)) {
						this.ordersByMonths = data.ordersByMonths;
					}

					data.orders.forEach(o => {
						const d = new Date(o.dtWork);
						const day = d.getDate();
						if (this.orders['day' + day] === undefined) {
							this.orders['day' + day] = [];
						}

						const i = this.orders.findIndex(order => order.id === o.id);

						if (i === -1) {
							this.orders['day' + day].push(o);
						} else {
							this.orders['day' + day][i] = o;
						}
					});
				}
			});
	};

	@action monthNameFirstForm = (month = null) => {
		if (month === null) {
			return this.months[this.month - 1] === undefined ? '' : this.months[this.month - 1][0];
		} else {
			return this.months[month - 1] === undefined ? '' : this.months[month - 1][0];
		}
	};

	@action monthNameSecondForm = (month = null) => {
		if (month === null) {
			return this.months[this.month - 1] === undefined ? '' : this.months[this.month - 1][1];
		} else {
			return this.months[month - 1] === undefined ? '' : this.months[month - 1][1];
		}
	};

	@action handleFilterExecutorChange = e => this.filters.executor = parseInt(e.target.value);
	@action handleFilterManagerChange = e => this.filters.manager = parseInt(e.target.value);
	@action handleFilterStatusChange = e => this.filters.status = parseInt(e.target.value);
	@action handleFilterAddressChange = e => this.filters.address = e.target.value;
	@action handleFilterClientChange = e => this.filters.client = e.target.value;
	@action handleFilterServiceChange = e => this.filters.service = parseInt(e.target.value);
	@action handleFilterWithoutX5Change = e => this.filters.withoutX5 = !this.filters.withoutX5;

	@action getPeriod = (month, year) => {
		const firstDayOfMonth = new Date(year, month - 1, 1);
		const lastDayOfMonth = new Date(firstDayOfMonth.getFullYear(), firstDayOfMonth.getMonth() + 1, 0);
		const startDay = new Date(firstDayOfMonth.getFullYear(), firstDayOfMonth.getMonth(), 2 - firstDayOfMonth.getDay());
		const endDay = new Date(lastDayOfMonth.getFullYear(), lastDayOfMonth.getMonth(), lastDayOfMonth.getDate() + 7 - lastDayOfMonth.getDay() + 1);

		return {startDay, endDay};
	}
}

export default CalendarStore;