import {observable, action, autorun, toJS} from 'mobx';
import axios from "axios";
import {Position, Toaster} from "@blueprintjs/core";
import get from 'lodash/get';

class MainStore {
	@observable isLoading = false;
	@observable isError = false;
	@observable error = '';
	@observable dadataToken = 'a2f0ba6f044f8995be22b47e507a41c42b132afa';
	@observable count = 0;
	@observable title = '';
	@observable toaster;
	@observable breadcrumbs = [];

	constructor(rootStore) {
		// const { authStore } = rootStore;
		// const a = new AuthStore();
		this.authStore = rootStore.authStore;
	}

	@action setToaster = toaster => this.toaster = toaster;
	@action addError = message => {
		this.toaster.show({
			icon: 'warning-sign',
			intent: 'danger',
			message: message,
		});
	};

	@action addMessage = message => {
		this.toaster.show({
			icon: 'tick',
			intent: 'success',
			message: message,
		});
	};

	@action startLoading = () => {
		this.count++;
		this.isError = false;
		this.isLoading = true;
	};

	@action stopLoading = () => {
		this.count = this.count === 0 ? 0 : this.count - 1;
		if (this.count < 0) {
			this.count = 0;
		}
		if (this.count === 0) {
			this.isLoading = false;
		}
	};

	@action setError = (error = '') => {
		this.isError = error !== '';
		this.error = error;
		if (Boolean(error)) {
			this.addError(error);
		}
	};

	@action clearError = () => {
		this.isError = false;
		this.error = '';
	};

	@action addErrors = errors => {
		errors.forEach(error => {
			this.addError(error);
		});
	};

	@action ajax = (url, data = {}, callback = null) => {
		this.startLoading();
		axios.post(url, data)
			.then(data => data.data)
			.then(data => {
				if (data.success || data.token !== undefined) {
					if (typeof callback === 'function') {
						callback(data);
					}
				} else {
					this.addError(data.error);
				}
			})
			.catch(error => {
				this.addError(error.toString());
				if (get(error, 'response.status', 500) === 401) {
					this.authStore.logout();
				}

				const err = get(error, 'response.data.error', false);
				if (err !== false) {
					if (typeof err === 'string') {
						this.addError(err);
					}

					if (typeof err === 'object') {
						if (Boolean(err.message)) {
							this.addError(err.message);
						}

						if (Boolean(err.exception)) {
							err.exception.forEach(e => {
								this.addError(e.message);
							})
						}
					}
				} else {
					this.addError(error.toString());
				}

				if (Boolean()) {
					this.addError(get(error, 'response.data.error'));
				} else {

				}
			})
			.finally(() => {
				this.stopLoading();
			});
	};

	@action ajaxWithoutLoader = (url, data = {}, callback = null) => {
		axios.post(url, data)
			.then(data => data.data)
			.then(data => {
				if (data.success || data.token !== undefined) {
					if (typeof callback === 'function') {
						callback(data);
					}
				} else {
					this.addError(data.error);
				}
			})
			.catch(error => {
				this.addError(error.toString());
				if (get(error, 'response.status', 500) === 401) {
					this.authStore.logout();
				}

				const err = get(error, 'response.data.error', false);
				if (err !== false) {
					if (typeof err === 'string') {
						this.addError(err);
					}

					if (typeof err === 'object') {
						if (Boolean(err.message)) {
							this.addError(err.message);
						}

						if (Boolean(err.exception)) {
							err.exception.forEach(e => {
								this.addError(e.message);
							})
						}
					}
				} else {
					this.addError(error.toString());
				}

				if (Boolean()) {
					this.addError(get(error, 'response.data.error'));
				} else {

				}
			})
			.finally(() => {

			});
	};

	@action gql = (query = {}, callback = null) => {
		this.startLoading();
		axios.post('/api/gql/', {query: query})
			.then(data => data.data)
			.then(data => {
				if (data.errors !== undefined) {
					data.errors.map(e => this.addError(e.message));
				} else if (typeof callback === 'function') {
					callback(data.data);
				}
			})
			.catch(error => {
				this.addError(error.toString());
				if (get(error, 'response.status', 500) === 401) {
					this.authStore.logout();
				}

				const err = get(error, 'response.data.error', false);
				if (err !== false) {
					if (typeof err === 'string') {
						this.addError(err);
					}

					if (typeof err === 'object') {
						if (Boolean(err.message)) {
							this.addError(err.message);
						}

						if (Boolean(err.exception)) {
							err.exception.forEach(e => {
								this.addError(e.message);
							})
						}
					}
				} else {
					this.addError(error.toString());
				}

				if (Boolean()) {
					this.addError(get(error, 'response.data.error'));
				} else {

				}
			})
			.finally(() => {
				this.stopLoading();
			});
	};

	@action setTitle = title => {
		this.title = title;
	};

	@action setBreadcrumbs = items => this.breadcrumbs = items;

	@action apiCatchErrors = error => {
		error.response.status === 400 && error.response.data.violations.forEach(e => this.addError(e.message));
		error.response.status === 404 && this.addError('Не найдено');
	};

	@action apiFetchItems = (url = '', callback = false) => {
		this.startLoading();
		axios.get(url)
			.then(data => data.data)
			.then(data => typeof callback === 'function' && callback(data))
			.catch(this.apiCatchErrors)
			.finally(this.stopLoading);
	};

	@action apiFetchItem = (url = '', id = 0, callback = false) => {
		this.startLoading();
		axios.get(`${url}/${id}`)
			.then(data => data.data)
			.then(data => typeof callback === 'function' && callback(data))
			.catch(this.apiCatchErrors)
			.finally(this.stopLoading);
	};

	@action apiDelete = (url = '', id = 0, callback = false) => {
		this.startLoading();
		axios.patch(`${url}/${id}`, {isActive: false})
			.then(data => data.data)
			.then(data => typeof callback === 'function' && callback(data))
			.catch(this.apiCatchErrors)
			.finally(this.stopLoading);
	};

	@action apiCreate = (url = '', data = {}, callback = false) => {
		this.startLoading();
		axios.post(url, data)
			.then(data => data.data)
			.then(data => typeof callback === 'function' && callback(data))
			.catch(this.apiCatchErrors)
			.finally(this.stopLoading);
	};

	@action apiUpdate = (url = '', id = 0, data = {}, callback = false) => {
		this.startLoading();
		axios.patch(`${url}/${id}`, data)
			.then(data => data.data)
			.then(data => typeof callback === 'function' && callback(data))
			.catch(this.apiCatchErrors)
			.finally(this.stopLoading);
	};

	atolConfig = {
		baseURL: 'https://testonline.atol.ru/possystem/v4/',
		headers: {
			post: {
				'Content-Type': 'application/json;charset=UTF-8',
				Accept: 'application/json',
				'Access-Control-Allow-Origin': '*'
			},
			common: {
				Authorization: ``
			}
		}
	};
	atolLogin = 'v4-online-atol-ru';
	atolPassword = 'iGFFuihss';

	@action atolGetToken = () => {
		const postData = {
			login: this.atolLogin,
			pass: this.atolPassword
		};

		axios.post('getToken', postData, this.atolConfig)
			.then(data => data.data)
			.then(data => {
				/*
				if (data.success || data.token !== undefined) {
					setData(data);
				} else {
					setError(data.error);
				}
				 */
			})
			.catch(error => console.log(error))
			.finally(() => false);
	};

	@action atol = () => {

	};
}

export default MainStore;
