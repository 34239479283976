import React from 'react';
import { inject, observer } from "mobx-react";
import {Button, Classes, Checkbox, Dialog} from "@blueprintjs/core";
import get from 'lodash/get';
import styled from "styled-components";

const MyTable = styled.table`
	box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.15);
	background: #f5f8fa;
	margin-top: 10px;
`;

const EditRewardDialogBP = inject('rewardStore')(observer(({rewardStore}) => {
	return (
		<Dialog isOpen={rewardStore.isEditDialogOpen} onClose={rewardStore.closeEditDialog} title={`Оприходование исполнителя ${rewardStore.item.fio}`} usePortal={true} style={{width: '60%'}} canEscapeKeyClose={false} canOutsideClickClose={false}>
			{(rewardStore?.item?.orders ?? 0).length > 0 && <div className={Classes.DIALOG_BODY} style={{maxHeight: '500px', overflowY: 'auto'}}>
				{rewardStore.item.orders !== undefined ?
					rewardStore.item.orders.map(o => (
						<div className="bp3-control-group">
							<Checkbox checked={rewardStore.ids.includes(parseInt(o.id))} onChange={e => rewardStore.changeIds(e.target.checked, o.id)} />
							<button className="bp3-button" style={{width: '80px'}}>№{o.id}</button>
							<button className="bp3-button" style={{width: '110px'}}>{new Intl.NumberFormat('ru-RU').format(o.price) + ' руб.'}</button>
							<button className="bp3-button" style={{width: '150px'}}>{o.isMass ? new Date(o.dtWork).toLocaleFormat("%d.%m.%Y") : new Date(o.dtWork).toLocaleFormat("%d.%m.%Y %H:%M")}</button>
							<button className="bp3-button" style={{width: '35%', textOverflow: 'ellipsis'}}>{o.address.address}</button>
							<button className="bp3-button" style={{width: '32%', textOverflow: 'ellipsis'}}>{o.org === null ? get(o, 'people.name', '') : get(o, 'org.name', '')}</button>
						</div>
					))
					: null
				}
			</div>}

			{(rewardStore?.item?.orders ?? 0).length > 0 && <div className={Classes.DIALOG_FOOTER}>
				<div className={Classes.DIALOG_FOOTER_ACTIONS}>
					<Button onClick={rewardStore.closeRewards} intent="success">Сохранить</Button>
					<Button onClick={rewardStore.closeEditDialog}>Закрыть</Button>
				</div>
			</div>}

			{rewardStore.histories.length > 0 &&
				<div className={Classes.DIALOG_BODY} style={{height: '300px', overflowY: 'auto'}}>
					<b>История оприходования</b>

					<MyTable className='bp3-html-table bp3-html-table-bordered bp3-small bp3-interactive' width='100%'>
						<thead>
							<th width='150' align='center'>Дата</th>
							<th width='150' align='center'>Сумма</th>
							<th align='center'>Заказы, №</th>
						</thead>

						<tbody>
							{rewardStore.histories.map((h, key) => (
								<tr key={key}>
									<td align='center'>{h.date}</td>
									<td align='center'>{new Intl.NumberFormat('ru-RU').format(h.total) + ' руб.'}</td>
									<td>{h.orders.join(', ')}</td>
								</tr>
							))}
						</tbody>
					</MyTable>
				</div>
			}
		</Dialog>
	);
}));

export default EditRewardDialogBP;