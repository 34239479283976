import {observable, action, computed, autorun, toJS} from 'mobx';
import EditUserDialogBP from "../components/EditUserDialogBP";
import React from "react";

class UserStore {
	defaultUser = {
		id: 0,
		fio: '',
		username: '',
		password: '',
		email: '',
		phone: '',
		roles: ['ROLE_USER'],
		enabled: true,
		isForceSMS: true,
	};
	terms = ['пользователь', 'пользователя', 'пользователей'];
	onCreateButtonTitle = "Новый пользователь";
	titleField = "title";
	headers = [
		{ id: 'fio', label: 'ФИО', sort: i => i.fio.trim() },
		{ id: 'phone', label: 'Телефон', func: p => p.replace(/^.*(\d{3})(\d{3})(\d{2})(\d{2})$/, '+7 ($1) $2-$3-$4'), sort: i => i.phone.replace(/^.*(\d{3})(\d{3})(\d{2})(\d{2})$/, '+7 ($1) $2-$3-$4') },
		{ id: 'email', label: 'E-Mail', sort: i => i.email.toLowerCase().trim() },
		{ id: 'roles', label: 'Роль', func: r => {
				if (r.length === 0) return '';
				switch (r[0]) {
					case 'ROLE_USER':
						return 'Испольнитель';
					case 'ROLE_OPERATOR':
						return 'Оператор';
					case 'ROLE_ADMIN':
						return 'Администратор';
					default:
						return '';
				}
			}
		},
		{ id: 'enabled', label: 'Статус', func: i => i ? 'Активен' : 'Заблокирован' },
	];
	@observable users = [];
	@observable user = this.defaultUser;
	@observable isEditDialogOpen = false;
	@observable errors = [];

	constructor(rootStore) {
		this.mainStore = rootStore.mainStore;
		this.user = this.defaultUser;
	}

	@action clear = () => {
		this.users = [];
		this.user = this.defaultUser;
	};

	@action fetch = () => this.mainStore.ajax('/api/users', {}, data => this.users = data.users);

	@action create = () => {
		this.user = this.defaultUser;
		this.openEditDialog();
	};

	@action read = id => {
		this.mainStore.ajax('/api/user/' + id, {}, data => {
			this.user = {...data.user, password: '', roles: data.user.roles.length === 0 ? ['ROLE_USER'] : data.user.roles};
			this.openEditDialog();
		});
	};

	@action update = () => {
		this.errors = [];

		// if (this.user.fio.length < 2) { this.errors.push('Имя не может быть короче 2 символов'); }
		// if (this.user.username.length < 2) { this.errors.push('Логин не может быть короче 2 символов'); }
		// if (/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i.test(this.user.email.trim()) === false) { this.errors.push('Некорректный E-Mail') }
		// if (this.user.phone.replace(/\D+/g, '').length < 10) { this.errors.push('Некорректный номер телефона') }
		// if ((this.user.id > 0 && this.user.password.trim().length > 0 && this.user.password.trim().length < 6) || (this.user.id === 0 && this.user.password.trim().length < 6)) { this.errors.push('Пароль не может быть короче 6 символов') }

		this.mainStore.ajax('/api/user/update', this.user, () => {
			this.closeEditDialog();
			this.fetch();
		});
	};

	// @action delete = id => this.mainStore.ajax('/api/user/delete/' + id, {}, () => this.fetch());

	@action edit = id => this.read(id);

	@action set = (field, value) => eval('this.user.' + field + ' = value');

	@action deleteError = (d, t) => {
		if (d) {
			this.errors.splice(0, 1);
		} else {
			const index = this.errors.findIndex(e => e === t);
			if (index !== -1) {
				this.errors.splice(index, 1);
			}
		}
	};

	@action openEditDialog = () => this.isEditDialogOpen = true;
	@action closeEditDialog = () => this.isEditDialogOpen = false;

	@computed get items() { return this.users; }
	@computed get editDialogTitle() { return this.user.id === 0 ? 'Новый пользователь' : 'Изменение пользователя'; };
	@computed get editDialog() { return <EditUserDialogBP/>; }
}

export default UserStore;
