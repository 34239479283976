import React, {useState} from 'react';
import {inject, observer} from "mobx-react";
import {Select} from "@blueprintjs/select";
import {Button, MenuItem} from "@blueprintjs/core";
import get from 'lodash/get';

const SelectClient = inject('orderStore')(observer(({highlightText = null, orderStore, ...props}) => {
	const [clientNeedle, setClientNeedle] = useState('');

	return (
		<Select
			items={orderStore.clients.filter(c => c.title.toLowerCase().includes(clientNeedle.toLowerCase()))}
			itemRenderer={(item, { handleClick, modifiers, query }) => (
				<MenuItem
					active={modifiers.active}
					disabled={modifiers.disabled}
					label={null}
					key={item.id}
					onClick={handleClick}
					text={highlightText(item.title, query)}
					multiline={true}
				/>
			)}
			onItemSelect={client => orderStore.set('org.client', client)}
			inputProps={{placeholder: 'Введите название клиента...', fill: true}}
			popoverProps={{fill: true, width: '100%'}}
			onQueryChange={needle => setClientNeedle(needle)}
			createNewItemFromQuery={query => ({id: 0, name: query})}
			createNewItemRenderer={(query, active, handleClick) => (
				<MenuItem
					icon="add"
					text={`Новое имя клиента "${query}"`}
					active={active}
					onClick={() => orderStore.set('org.client', {id: 0, title: query})}
					shouldDismissPopover={true}
				/>
			)}
			allowCreate={true}
		>
			<Button
				text={get(orderStore.order, 'org.client.title', '')}
				// rightIcon="double-caret-vertical"
				fill={true}
				alignText='left'
			/>
		</Select>
	);
}));

export default SelectClient;