import React from 'react';
import {inject, observer} from "mobx-react";
import {Select} from "@blueprintjs/select";
import {Button, MenuItem} from "@blueprintjs/core";
import {toJS} from "mobx";
import get from 'lodash/get';

const SelectLocation = inject('orderStore')(observer(({highlightText = null, orderStore, ...props}) => {
	const handleSelect = l => {
		orderStore.set('location', l);
	};

	return (
		<Select
			items={orderStore.locations}
			itemRenderer={(item, { handleClick, modifiers, query }) => {
				return (
					<MenuItem
						// active={modifiers.active}
						active={item.id === get(orderStore.order, 'location.id', 0)}
						disabled={modifiers.disabled}
						label={item.id === 0 ? '[новый]' : null}
						key={item.id + item.title}
						onClick={handleClick}
						text={highlightText(item.title, query)}
						multiline={true}
					/>
				)
			}}
			onItemSelect={handleSelect}
			inputProps={{placeholder: 'Название локации...', fill: true}}
			popoverProps={{fill: true, width: '100%'}}
			createNewItemFromQuery={query => ({id: 0, title: query})}
			createNewItemRenderer={(query, active, handleClick) => (
				<MenuItem
					icon="add"
					text={`Новая локация "${query}"`}
					active={active}
					onClick={() => orderStore.set('location', {id: 0, title: query})}
					shouldDismissPopover={true}
				/>
			)}
			allowCreate={true}
		>
			<Button
				text={get(orderStore.order, 'location.title', '')}
				// rightIcon="double-caret-vertical"
				fill={true}
				alignText='left'
			/>
		</Select>
	);
}));

export default SelectLocation;