import React from 'react';
import {inject, observer} from "mobx-react";
import {
    Button,
    Classes,
    ControlGroup,
    Dialog,
    FormGroup,
    Intent, MenuItem,
    NumericInput,
    Position,
    TextArea
} from "@blueprintjs/core";
import styled from "styled-components";
import {DateInput} from "@blueprintjs/datetime";
import {Select} from "@blueprintjs/select";
import MomentLocaleUtils from 'react-day-picker/moment'
import 'moment/locale/ru';
import get from 'lodash/get'
import highlightText from "../utils/highlightText";

const Label = styled.label`
    width: 150px;
    flex-shrink: 0 !important;
    flex-grow: 0 !important;
`;

const EditContractDialog = inject('contractStore')(observer(({contractStore}) => {
    if (contractStore.item.id === undefined) {
        return null;
    }

    return (
        <Dialog isOpen={contractStore.isEditDialogOpen} onClose={contractStore.closeEditDialog} title={contractStore.editDialogTitle} usePortal={true} canEscapeKeyClose={false} canOutsideClickClose={false}>
            <div className={Classes.DIALOG_BODY}>
                <FormGroup>
                    <ControlGroup className="bp3-fill" style={{marginBottom: '5px'}}>
                        <Label className="bp3-button" htmlFor="title">Тип</Label>
                        <div className='bp3-select  bp3-fill'>
                            <select value={contractStore.item.type.id} onChange={contractStore.setType}>
                                {contractStore.types.map((t, key) => <option value={t.id} key={key}>{t.title}</option>)}
                            </select>
                        </div>
                    </ControlGroup>

                    <ControlGroup style={{marginBottom: '5px'}}>
                        <Label className="bp3-button" htmlFor="title">Статус</Label>
                        <div className='bp3-select bp3-fill'>
                            <select value={contractStore.item.status.id} onChange={e => contractStore.set('status.id', e.target.value)}>
                                {contractStore.statuses.map((s, key) => <option value={s.id} key={key}>{s.title}</option>)}
                            </select>
                        </div>
                    </ControlGroup>

                    <ControlGroup style={{marginBottom: '5px'}}>
                        <Label className="bp3-button" htmlFor="title">Номер</Label>
                        <input className='bp3-input bp3-fill' value={contractStore.item.number} onChange={e => contractStore.set('number', e.target.value)} />
                    </ControlGroup>

                    <ControlGroup style={{marginBottom: '5px'}}>
                        <Label className="bp3-button" htmlFor="title">Дата</Label>
                        <DateInput
                            fill
                            locale="ru"
                            localeUtils={MomentLocaleUtils}
                            value={typeof contractStore.item.startedAt === 'string' ? new Date(contractStore.item.startedAt) : contractStore.item.startedAt}
                            formatDate={d => d.toLocaleFormat('%d.%m.%Y')}
                            // timePrecision={TimePrecision.MINUTE}
                            onChange={e => contractStore.set('startedAt', e)}
                            parseDate={str => new Date(str.replace(/\D+/g, '').replace(/^(\d{2})(\d{2})(\d{4})$/, '$3-$2-$1')) }
                            popoverProps={{ position: Position.BOTTOM }}
                        />
                    </ControlGroup>

                    <ControlGroup style={{marginBottom: '5px'}}>
                        <Label className="bp3-button" htmlFor="title">Срок (месяцев)</Label>
                        <NumericInput value={contractStore.item.months}
                                      style={{width: '100px', display: 'inline-block', marginRight: '10px'}}
                                      onValueChange={e => contractStore.set('months', e)}
                                      buttonPosition="right"
                                      min={0}
                                      allowNumericCharactersOnly={true}
                                      fill
                        />
                    </ControlGroup>

                    <ControlGroup style={{marginBottom: '5px'}}>
                        <Label className="bp3-button" htmlFor="title">Юр. лицо</Label>
                        <Select
                            items={contractStore.orgs}
                            itemRenderer={(item, { handleClick, modifiers, query }) => {
                                return (
                                    <MenuItem
                                        active={modifiers.active}
                                        disabled={modifiers.disabled}
                                        label={item.id === 0 ? '[новое]' : null}
                                        key={item.id + item.name}
                                        onClick={handleClick}
                                        text={highlightText(item.name, query)}
                                        multiline={true}
                                        // style={{backgroundColor: item.id === 0 ? '#FFCC99' : '#66FF66'}}
                                    />
                                );
                            }}
                            onItemSelect={e => {contractStore.set('people', null); contractStore.set('org', e);}}
                            inputProps={{placeholder: 'Введите название или ИНН...', fill: true}}
                            popoverProps={{fill: true, width: '100%'}}
                            onQueryChange={contractStore.findOrgs}
                        >
                            <Button
                                text={get(contractStore.item, 'org.name', '')}
                                // rightIcon="double-caret-vertical"
                                fill={true}
                                alignText='left'
                            />
                        </Select>
                    </ControlGroup>

                    <ControlGroup style={{marginBottom: '5px'}}>
                        <Label className="bp3-button" htmlFor="title">Физ. лицо</Label>
                        <Select
                            items={contractStore.peoples}
                            itemRenderer={(item, { handleClick, modifiers, query }) => {
                                return (
                                    <MenuItem
                                        active={modifiers.active}
                                        disabled={modifiers.disabled}
                                        label={item.id === 0 ? '[новое]' : null}
                                        key={item.id + item.name}
                                        onClick={handleClick}
                                        text={highlightText(item.name, query)}
                                        multiline={true}
                                        // style={{backgroundColor: item.id === 0 ? '#FFCC99' : '#66FF66'}}
                                    />
                                );
                            }}
                            onItemSelect={e => {contractStore.set('org', null); contractStore.set('people', e);}}
                            inputProps={{placeholder: 'Введите имя...', fill: true}}
                            popoverProps={{fill: true, width: '100%'}}
                            onQueryChange={contractStore.findPeople}
                        >
                            <Button
                                text={get(contractStore.item, 'people.name', '')}
                                // rightIcon="double-caret-vertical"
                                fill={true}
                                alignText='left'
                            />
                        </Select>
                    </ControlGroup>

                    <ControlGroup>
                        <Label className="bp3-button" htmlFor="title">Информация</Label>
                        <TextArea value={contractStore.item.info}
                                  onChange={e => contractStore.set('info', e.target.value)}
                                  fill
                                  intent={Intent.PRIMARY}
                        />
                    </ControlGroup>
                </FormGroup>
            </div>

            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button onClick={contractStore.update} intent="success">Сохранить</Button>
                    <Button onClick={contractStore.closeEditDialog}>Закрыть</Button>
                </div>
            </div>
        </Dialog>
    );
}));

export default EditContractDialog;