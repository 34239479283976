import React, {useState} from 'react';
import {inject, observer} from "mobx-react";
import {
	Button,
	Checkbox,
	Classes,
	ControlGroup,
	Dialog,
	FormGroup,
	Label,
	Toast,
	Toaster
} from "@blueprintjs/core";
import styled from 'styled-components';

const LabelStyled = styled(Label)`
	width: 100px;
	flex: 0 0 100px;
	position: absolute;
	height: 100%;
	padding-top: 6px !important;
	text-align: center;
	z-index: 15;
	border-right: 1px solid rgba(16, 22, 26, 0.15);
`;

const InputStyled = styled.input`
	padding-left: 110px !important;
	border-radius: 3px !important;
`;

const ControlGroupStyled = styled(ControlGroup)`
	margin-bottom: 2px;
`;

const LockIcon = styled.button`
	z-index: 15 !important;
`;

const SelectStyled = styled.select`
	padding-left: 110px !important;
	border-radius: 3px !important;
`;

const EditUserDialogBP = inject('mainStore', 'userStore')(observer(({mainStore, userStore}) => {
	const [isPassword, setIsPassword] = useState(true);

	return (
		<Dialog isOpen={userStore.isEditDialogOpen} onClose={userStore.closeEditDialog} title={userStore.editDialogTitle} usePortal={true} canEscapeKeyClose={false}>
			<Toaster canEscapeKeyClear autoFocus>
				{userStore.errors.map((e, i) => <Toast intent="danger" message={e} icon="warning-sign" key={i} onDismiss={d => userStore.deleteError(d, e)} timeout={5000} />)}
			</Toaster>

			<div className={Classes.DIALOG_BODY}>
				<FormGroup>
					<ControlGroupStyled fill className="bp3-input-group">
						<LabelStyled htmlFor="fio">Имя</LabelStyled>
						<InputStyled id="fio" className={Classes.INPUT + ' ' + 'bp3-fill'} value={userStore.user.fio} onChange={e => userStore.set('fio', e.target.value)} />
					</ControlGroupStyled>

					<ControlGroupStyled fill className="bp3-input-group">
						<LabelStyled htmlFor="email">E-Mail</LabelStyled>
						<InputStyled id="email" className={Classes.INPUT + ' ' + 'bp3-fill'} value={userStore.user.email} onChange={e => userStore.set('email', e.target.value)} />
					</ControlGroupStyled>

					<ControlGroupStyled fill className="bp3-input-group">
						<LabelStyled htmlFor="phone">Телефон</LabelStyled>
						<InputStyled id="phone" className={Classes.INPUT + ' ' + 'bp3-fill'} value={userStore.user.phone} onChange={e => userStore.set('phone', e.target.value)} />
					</ControlGroupStyled>

					<ControlGroupStyled fill className="bp3-input-group">
						<LabelStyled htmlFor="username">Логин</LabelStyled>
						<InputStyled id="username" className={Classes.INPUT + ' ' + 'bp3-fill'} value={userStore.user.username} onChange={e => userStore.set('username', e.target.value)} />
					</ControlGroupStyled>

					<ControlGroupStyled fill className="bp3-input-group">
						<LabelStyled htmlFor="password">Пароль</LabelStyled>
						<InputStyled type={isPassword ? 'password' : 'text'} id="password" className={Classes.INPUT} value={userStore.user.password} onChange={e => userStore.set('password', e.target.value)} />
						<LockIcon className={`bp3-button bp3-minimal bp3-intent-warning bp3-icon-${isPassword ? 'lock' : 'unlock'}`} onClick={() => setIsPassword(!isPassword)} />
					</ControlGroupStyled>

					<ControlGroupStyled fill className="bp3-input-group">
						<LabelStyled htmlFor="password">Роль</LabelStyled>
						<div className="bp3-select bp3-fill">
							<SelectStyled value={userStore.user.roles[0]} onChange={e => userStore.set('roles', [e.target.value])}>
								<option value="ROLE_USER">Исполнитель</option>
								<option value="ROLE_OPERATOR">Оператор</option>
								<option value="ROLE_ADMIN">Администратор</option>
							</SelectStyled>
						</div>
					</ControlGroupStyled>

					<ControlGroup>
						<Checkbox checked={userStore.user.enabled} onChange={e => userStore.set('enabled', e.target.checked)} />
						<Label style={{lineHeight: '30px'}}>{userStore.user.enabled ? 'Активен' : 'Заблокирован'}</Label>
					</ControlGroup>

					<ControlGroup>
						<Checkbox checked={userStore.user.isForceSMS} onChange={e => userStore.set('isForceSMS', e.target.checked)} />
						<Label style={{lineHeight: '30px'}}>{userStore.user.isForceSMS ? 'SMS сообщения' : 'Push сообщения'}</Label>
					</ControlGroup>
				</FormGroup>

			</div>

			<div className={Classes.DIALOG_FOOTER}>
				<div className={Classes.DIALOG_FOOTER_ACTIONS}>
					<Button onClick={userStore.update} intent="success">Сохранить</Button>
					<Button onClick={userStore.closeEditDialog}>Закрыть</Button>
				</div>
			</div>
		</Dialog>
	);
}));

export default EditUserDialogBP;