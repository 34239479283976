import React from 'react';
// import 'mobx-react-lite/batchingForReactDom';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'mobx-react';
import { configure} from 'mobx';
import axios from 'axios';
// import { DndProvider, useDrag, useDrop } from 'react-dnd'
// import { HTML5Backend } from 'react-dnd-html5-backend'

import App from './containers/App.js';
import { Preloader } from './components/Preloader';

import rootStore from './store/RootStore';
import ErrorBoundary from './components/ErrorBoundary';

require('normalize.css/normalize.css');
require('@blueprintjs/icons/lib/css/blueprint-icons.css');
require('@blueprintjs/core/lib/css/blueprint.css');
require('@blueprintjs/table/lib/css/table.css');
require('@blueprintjs/select/lib/css/blueprint-select.css');
require('@blueprintjs/datetime/lib/css/blueprint-datetime.css');
require('./fix.css');

// configure({enforceActions: true});

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'application/json';
axios.defaults.headers.common['Authorization'] = `Bearer ${rootStore.authStore.token}`;

render(
	// <ErrorBoundary>
		<Provider { ...rootStore }>
			{/*<DndProvider backend={HTML5Backend}>*/}
			<Preloader/>
			<BrowserRouter>
				<App />
			</BrowserRouter>
			{/*</DndProvider>*/}
		</Provider>
	// </ErrorBoundary>,
	,
	document.getElementById('root')
);
