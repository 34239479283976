import {observable, action, toJS} from 'mobx';
import axios from 'axios';

class AuthStore {
	@observable isLogged = false;
	@observable user = {};
	@observable token = '';

	constructor(rootStore) {
		this.mainStore = rootStore.mainStore;
		const token = localStorage.getItem('token');
		const user = localStorage.getItem('user');

		try {
			if (token !== null && user !== null) {
				this.token = token;
				this.user = JSON.parse(user);
				this.isLogged = true;
				axios.defaults.headers.post['Content-Type'] = 'application/json';
				axios.defaults.headers.post['Accept'] = 'application/json';
				axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
			}
		} catch (e) {
			this.isLogged = false;
			this.token = '';
			this.user = {};
		}
	}

	@action login = (username, password) => {
		this.mainStore.ajax('/api/auth/login', {username, password}, data => {
			if (data.code === undefined) {
				localStorage.setItem('token', data.token);
				localStorage.setItem('user', JSON.stringify(data.user));
				this.user = data.user;
				this.token = data.token;

				axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;

				this.isLogged = true;
			}
		});
	};

	@action logout = () => {
		axios.defaults.headers.common['Authorization'] = `Bearer`;
		localStorage.removeItem('token');
		localStorage.removeItem('user');
		this.isLogged = false;
		this.token = '';
		this.user = {};
	};

	@action isUser = () => {
		try {
			return this.user.roles[0] === 'ROLE_USER';
		} catch (e) {
			return true;
		}
	};

	@action isOperator = () => {
		try {
			return this.user.roles[0] === 'ROLE_OPERATOR';
		} catch (e) {
			return false;
		}
	};

	@action isAdmin = () => {
		try {
			return this.user.roles[0] === 'ROLE_ADMIN';
		} catch (e) {
			return false;
		}
	};
}

export default AuthStore;