import React from 'react';
import DefaultStore from './DefaultStore'
import {observable, action, computed, toJS} from 'mobx';
import EditAddressDialog from "../components/EditAddressDialog";
import get from 'lodash/get';

class AddressStore extends DefaultStore {
    defaultItem = {
        id: 0,
        address: '',
        dadata: '',
        isChecked: false,
        area: 0,
        lat: 0,
        lng: 0,
        phones: [],
        shopNumber: '',
        director: '',
        directorPhone: [],
        comment: '',
        cluster: ''
    };
    @observable orgs = [];
    terms = ['адрес', 'адреса', 'адресов'];
    onCreateButtonTitle = "Новый адрес";
    titleField = "address";
    headers = [
        {id: 'address', label: 'Адрес', sort: i => i.address.toLowerCase().trim(), width: 500},
        {id: i => i.isChecked ? 'да' : 'нет', label: 'Проверено', sort: i => i.isChecked ? 'да' : 'нет'},
        {id: 'area', label: 'Площадь', sort: i => parseInt(i.area)},
        {id: 'lat', label: 'Lat', sort: i => parseFloat(i.lat)},
        {id: 'lng', label: 'Lng', sort: i => parseFloat(i.lng)},
        {id: 'shopNumber', label: 'Магазина №', sort: i => i.shopNumber},
        {id: 'cluster', label: 'Кластер', sort: i => i.cluster},
        {id: i => i.people.map(j => j.name).concat(i.organizations.map(j => `${j.name}${j.client !== null ? ` (${j.client.title})` : ''}`)), label: 'Клиенты', sort: i => i.people.map(j => j.name).concat(i.organizations.map(j => `${j.name}${j.client !== null ? ` (${j.client.title})` : ''}`)).join(', ')}
    ];
    timer = null;
    @observable suggestions = [];
    @observable clients = [];

    @computed get editDialogTitle() { return this.item.id === 0 ? 'Новый адресс' : 'Изменение адреса'; }
    @computed get editDialog() { return <EditAddressDialog />; }

    @action fetch = () => this.mainStore.ajax('/api/addresses', {}, data => this.items = data.addresses);

    @action fetchWithClients = () => this.mainStore.ajax('/api/addresses_clients', {}, data => {
        this.items = data.addresses;

        data.addresses.forEach(a => {
            a.people.forEach(p => {
                if (this.clients.filter(i => i.isPeople && i.id === p.id).length === 0) {
                    this.clients.push({
                        isPeople: true,
                        id: p.id,
                        name: p.name
                    });
                }
            });

            a.organizations.forEach(o => {
                if (this.clients.filter(i => !i.isPeople && i.id === o.id).length === 0) {
                    const contracts = o.contracts.filter(i => i.isActive).map(i => i.number);

                    this.clients.push({
                        isPeople: false,
                        id: o.id,
                        name: `${o.name}${o.client !== null && o.name !== o.client.title ? ` (${o.client.title})` : ''}${contracts.length > 0 ? ` (${contracts.join(', ')})` : ''}`
                    })
                }
            });
        });

        this.clients = this.clients.slice().sort((a, b) => {
            if (a.name.toLowerCase().trim() === b.name.toLowerCase().trim()) return 0;
            return a.name.toLowerCase().trim() > b.name.toLowerCase().trim() ? 1 : -1;
        });
    });

    @action clear = () => {
        this.items = [];
        this.item = {};
        this.clients = [];
        this.closeEditDialog();
    };

    @action fetchItem = id => this.mainStore.ajax(`/api/address/${id}`, {}, data => {
        let phones = [];
        try {
            phones = JSON.parse(data.address.phones);
        } catch (e) {
            phones = [];
        }
        this.item = {...this.defaultItem, ...data.address, phones: phones, directorPhone: data.address.directorPhone.trim() === '' ? [] : data.address.directorPhone.trim().split(',')};
        this.suggestions = [];
        this.openEditDialog();
    });

    @action edit = id => this.fetchItem(id);

    /*
    @action create = () => {
        this.item = this.defaultItem;
        this.openEditDialog();
    };
     */

    @action searchDadata = () => {
        this.mainStore.startLoading();
        fetch('https://dadata.ru/api/v2/suggest/address', {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token ' + this.mainStore.dadataToken
                },
                body: JSON.stringify({
                    query: this.item.address,
                    count: 10
                })
            })
            .then(res => res.json())
            .then(data => {
                this.suggestions = data.suggestions.map(s => ({address: s.value, dadata: JSON.stringify(s), lat: get(s, 'data.geo_lat', 0) ?? 0, lng: get(s, 'data.geo_lon', 0) ?? 0}));
            })
            .catch(alert)
            .finally(() => {
                this.mainStore.stopLoading();
            });
    };

    @action searchDadata2 = id=> {
        const index = this.items.findIndex(i => i.id === id);
        if (index === -1) {
            return;
        }

        this.mainStore.startLoading();
        fetch('https://dadata.ru/api/v2/suggest/address', {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token ' + this.mainStore.dadataToken
                },
                body: JSON.stringify({
                    query: this.items[index].address,
                    count: 10
                })
            })
            .then(res => res.json())
            .then(data => {
                this.items[index].suggestion = data.suggestions.length > 0 ? data.suggestions.map(s => ({address: s.value, dadata: JSON.stringify(s), lat: get(s, 'data.geo_lat', 0) ?? 0, lng: get(s, 'data.geo_lon', 0) ?? 0}))[0] : false;
            })
            .catch(alert)
            .finally(() => {
                this.mainStore.stopLoading();
            });
    };

    @action saveAddress = id => {
        const address = this.items.find(i => i.id === id);
        const index = this.items.findIndex(i => i.id === id);
        if (address === undefined) {
            return;
        }

        const data = {
            id: address.id,
            address: address.suggestion.address,
            dadata: address.suggestion.dadata,
            isChecked: true,
            area: address.area,
            lat: address.suggestion.lat > 0 ? address.suggestion.lat : address.lat,
            lng: address.suggestion.lng > 0 ? address.suggestion.lng : address.lng,
        };

        this.mainStore.ajax(`/api/address/update/${data.id}`, {...data}, () => {
            this.items[index] = {...data, dadata: ''}
        });
    };

    @action saveAll = () => {
        const items = this.items.filter(i =>  i.suggestion !== undefined && i.suggestion !== false);
        items.forEach(i => this.saveAddress(i.id));
    };

    @action setDadata = index => {
        this.item = {...this.item, ...this.suggestions[index], isChecked: true};
    };

    @action update = () => this.mainStore.ajax(`/api/address/update/${this.item.id}`, {...this.item, phones: JSON.stringify(this.item.phones), directorPhone: this.item.directorPhone.length === 0 ? '' : this.item.directorPhone.join(',')}, () => {
        const index = this.items.findIndex(i => i.id === this.item.id);
        if (index !== -1) {
            this.items[index].address = this.item.address;
            this.items[index].area = this.item.area;
            this.items[index].lat = this.item.lat;
            this.items[index].lng = this.item.lng;
        }
        this.closeEditDialog();
        //this.fetch();
    });

    @action addPhone = phone => {
        try {
            if (phone[0].replace(/\D+/g, '').length < 10) {
                this.mainStore.addError(`Некорректный номер телефона (меньше 10 цифр): ${phone[0]}`);
                return false;
            }

            this.item.phones.push(phone[0].replace('/\D+/g', '').replace(/^.*(\d{10})$/, '$1'));
            return true;
        } catch (e) {
            this.mainStore.addError(`Некорректный номер телефона: ${phone[0]}`);
            return false;
        }
    };

    @action removePhone = (phone, index) => {
        try {
            this.item.phones.splice(index, 1);
        } catch (e) {

        }
    };

    @action addDirectorPhone = phone => {
        try {
            if (phone[0].replace(/\D+/g, '').length < 10) {
                this.mainStore.addError(`Некорректный номер телефона (меньше 10 цифр): ${phone[0]}`);
                return false;
            }

            this.item.directorPhone.push(phone[0].replace('/\D+/g', '').replace(/^.*(\d{10})$/, '$1'));
            return true;
        } catch (e) {
            this.mainStore.addError(`Некорректный номер телефона: ${phone[0]}`);
            return false;
        }
    };

    @action removeDirectorPhone = (phone, index) => {
        try {
            this.item.directorPhone.splice(index, 1);
        } catch (e) {

        }
    };
}

export default AddressStore;