import React, { useState } from 'react';
import { inject, observer } from "mobx-react";
import {Button, MenuItem, Classes, ControlGroup, Dialog, FormGroup, HTMLSelect, TagInput} from "@blueprintjs/core";
import { Omnibar, Select } from "@blueprintjs/select";
import get from 'lodash/get';
import highlightText from "../utils/highlightText";

const SelectAddress = inject('orgStore')(observer(({highlightText = null, orgStore, ...props}) => {
	const handleSelectAddress = a => orgStore.address = a.address;

	return (
		<Select
			items={orgStore.suggestAddress}
			itemRenderer={(item, { handleClick, modifiers, query }) => (
				<MenuItem
					active={modifiers.active}
					disabled={modifiers.disabled}
					key={item.id + item.address}
					onClick={handleClick}
					text={highlightText(item.address, query)}
					multiline={true}
				/>
			)}
			onItemSelect={handleSelectAddress}
			inputProps={{placeholder: 'Введите адрес...', fill: true}}
			popoverProps={{fill: true, width: '100%'}}
			onQueryChange={orgStore.findAddress}
		>
			<button type="button" className="bp3-button bp3-fill bp3-align-left" style={{minHeight: '30px', margin: 0}}>{orgStore.address}</button>
		</Select>
	);
}));

const SearchClient = inject('orgStore')(observer(({isSearchOpen = false, setIsSearchOpen = null, highlightText = null, orgStore, ...props}) => {
	const itemRenderer = (item, {handleClick, modifiers, query}) => {
		if (!modifiers.matchesPredicate) {
			return null;
		}

		const text = `${item.value}, ИНН ${item.data.inn}`;
		const label = '[создать]';
		const key = `${item.data.inn}:${item.data.kpp}`;

		return (
			<MenuItem
				active={modifiers.active}
				disabled={modifiers.disabled}
				label={label}
				key={key}
				onClick={handleClick}
				text={highlightText(text, query)}
				multiline={true}
			/>
		);
	};

	const onItemSelect = item => {
		handleSelectItem(item);
		setIsSearchOpen(false);
	};

	const handleSelectItem = item => {
		orgStore.set('headName', get(item, 'data.management.name', ''));
		orgStore.set('headPosition', get(item, 'data.management.post', ''));
		orgStore.set('inn', get(item, 'data.inn', ''));
		orgStore.set('kpp', get(item, 'data.kpp', ''));
		orgStore.set('legalAddress', get(item, 'data.address.data.value', ''));
		orgStore.set('name', get(item, 'value', ''));
		orgStore.set('ogrn', get(item, 'data.ogrn', ''));
		orgStore.set('client.title', get(item, 'value', ''));
	};

	return (
		<Omnibar isOpen={isSearchOpen}
		         items={orgStore.suggestOrg}
		         itemRenderer={itemRenderer}
		         onItemSelect={onItemSelect}
		         onClose={() => setIsSearchOpen(false)}
		         inputProps={{placeholder: 'Введите название юр.лица или его ИНН...'}}
		         onQueryChange={orgStore.findOrg}
		         allowCreate={true}
		/>
	);
}));

const EditOrgDialogBP = inject('orgStore')(observer(({orgStore}) => {
	const [clientNeedle, setClientNeedle] = useState('');
	const [isSearchOpen, setIsSearchOpen] = useState(false);
	
	const groupStyle = {
		marginBottom: '2px',
	};

	const labelStyle = {
		width: '150px',
		flexShrink: 0,
		flexGrow: 0,
	};

	return (
		<Dialog isOpen={orgStore.isEditDialogOpen}
				onClose={orgStore.closeEditDialog}
				title={orgStore.editDialogTitle}
				usePortal={true}
				style={{width: '60%', maxHeight: '80%'}}
				canEscapeKeyClose={false}
				canOutsideClickClose={false}
		>
			<div className={Classes.DIALOG_BODY}>
				{orgStore.item.id === 0 ? (
					<FormGroup>
						<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
							<Button className="bp3-button" small={true} text="Найти клиента" onClick={() => setIsSearchOpen(true)} fill={false} />
						</div>
					</FormGroup>
				) : null}

				<FormGroup>
					<ControlGroup className="bp3-fill" style={groupStyle}>
						<label className="bp3-button" style={labelStyle} htmlFor="client_title">Клиент</label>
						<Select
							items={orgStore.clients.filter(c => c.title.toLowerCase().includes(clientNeedle.toLowerCase()))}
							itemRenderer={(item, { handleClick, modifiers, query }) => (
								<MenuItem
									active={modifiers.active}
									disabled={modifiers.disabled}
									label={null}
									key={item.id}
									onClick={handleClick}
									text={highlightText(item.title, query)}
									multiline={true}
								/>
							)}
							onItemSelect={client => orgStore.set('client', client)}
							inputProps={{placeholder: 'Введите название клиента...', fill: true}}
							popoverProps={{fill: true, width: '100%'}}
							onQueryChange={needle => setClientNeedle(needle)}
							createNewItemFromQuery={query => ({id: 0, name: query})}
							createNewItemRenderer={(query, active, handleClick) => (
								<MenuItem
									icon="add"
									text={`Новое имя клиента "${query}"`}
									active={active}
									onClick={() => orgStore.set('client', {id: 0, title: query})}
									shouldDismissPopover={true}
								/>
							)}
							allowCreate={true}
						>
							<Button
								text={get(orgStore, 'item.client.title', '')}
								// rightIcon="double-caret-vertical"
								fill={true}
								alignText='left'
							/>
						</Select>

					</ControlGroup>

					<ControlGroup className="bp3-fill" style={groupStyle}>
						<label className="bp3-button" style={labelStyle} htmlFor="org_name">Юр.лицо</label>
						<input className={Classes.INPUT + ' ' + 'bp3-fill'}
						       value={get(orgStore, 'item.name', '')}
						       onChange={e => orgStore.set('name', e.target.value)}
						       id='org_name'
						/>
					</ControlGroup>

					<ControlGroup className="bp3-fill" style={groupStyle}>
						<label className="bp3-button" style={labelStyle} htmlFor="org_head_name">Руководитель</label>
						<input className={Classes.INPUT + ' ' + 'bp3-fill'}
						       value={get(orgStore, 'item.headName', '')}
						       onChange={e => orgStore.set('headName', e.target.value)}
						       id='org_head_name'
						/>
					</ControlGroup>

					<ControlGroup className="bp3-fill" style={groupStyle}>
						<label className="bp3-button" style={labelStyle} htmlFor="org_head_position">Должность</label>
						<input className={Classes.INPUT + ' ' + 'bp3-fill'}
						       value={get(orgStore, 'item.headPosition', '')}
						       onChange={e => orgStore.set('headPosition', e.target.value)}
						       id='org_head_position'
						/>
					</ControlGroup>

					<ControlGroup className="bp3-fill" style={groupStyle}>
						<label className="bp3-button" style={labelStyle} htmlFor="org_inn">ИНН</label>
						<input className={Classes.INPUT + ' ' + 'bp3-fill'}
						       value={get(orgStore, 'item.inn', '')}
						       onChange={e => orgStore.set('inn', e.target.value)}
						       id='org_inn'
						/>
						<label className="bp3-button" style={labelStyle} htmlFor="org_kpp">КПП</label>
						<input className={Classes.INPUT + ' ' + 'bp3-fill'}
						       value={get(orgStore, 'item.kpp', '')}
						       onChange={e => orgStore.set('kpp', e.target.value)}
						       id='org_kpp'
						/>
					</ControlGroup>

					<ControlGroup className="bp3-fill" style={groupStyle}>
						<label className="bp3-button" style={labelStyle} htmlFor="org_ogrn">ОГРН</label>
						<input className={Classes.INPUT + ' ' + 'bp3-fill'}
						       value={get(orgStore, 'item.ogrn', '')}
						       onChange={e => orgStore.set('ogrn', e.target.value)}
						       id='org_ogrn'
						/>
						<label className="bp3-button" style={labelStyle} htmlFor="org_bik">БИК</label>
						<input className={Classes.INPUT + ' ' + 'bp3-fill'}
						       value={get(orgStore, 'item.bik', '')}
						       onChange={e => orgStore.set('bik', e.target.value)}
						       id='org_bik'
						/>
					</ControlGroup>

					<ControlGroup className="bp3-fill" style={groupStyle}>
						<label className="bp3-button" style={labelStyle} htmlFor="manager">Налогообложение</label>
						<HTMLSelect
							options={[
								{value: 0, label: 'Не определено'},
								{value: 1, label: 'УСН'},
								{value: 2, label: 'ОСНО'},
							]}
							value={get(orgStore, 'item.taxation', '')}
							onChange={e => orgStore.set('taxation', parseInt(e.currentTarget.value))}
						/>
					</ControlGroup>

					<ControlGroup className="bp3-fill" style={groupStyle}>
						<label className="bp3-button" style={labelStyle} htmlFor="phones">Телефоны</label>
						<TagInput fill={true}
						          values={orgStore.item.phones.map(p => p.number.replace(/\D+/g, '').replace(/^.*(\d{3})(\d{3})(\d{2})(\d{2})$/, '+7 $1 $2-$3-$4'))}
						          onAdd={orgStore.addPhone}
						          onRemove={orgStore.removePhone}
						/>
					</ControlGroup>

					<ControlGroup className="bp3-fill" style={groupStyle}>
						<label className="bp3-button" style={labelStyle} htmlFor="emails">E-mail</label>
						<TagInput fill={true}
						          values={orgStore.item.emails.map(e => e.email)}
						          onAdd={orgStore.addEmail}
						          onRemove={orgStore.removeEmail}
						/>
					</ControlGroup>

					<ControlGroup className="bp3-fill" style={groupStyle}>
						<label className="bp3-button" style={labelStyle} htmlFor="source">Источник</label>
						<HTMLSelect
							options={orgStore.sources.map(s => ({value: s.id, label: s.title}))}
							value={get(orgStore, 'item.source.id', '')}
							onChange={e => orgStore.set('source.id', e.currentTarget.value)}
						/>
					</ControlGroup>

					<ControlGroup className="bp3-fill" style={groupStyle}>
						<label className="bp3-button" style={labelStyle} htmlFor="manager">Менеджер</label>
						<HTMLSelect
							options={orgStore.managers.map(m => ({value: m.id, label: m.fio}))}
							value={get(orgStore, 'item.manager.id', '')}
							onChange={e => orgStore.set('manager.id', e.currentTarget.value)}
						/>
					</ControlGroup>

					<ControlGroup className="bp3-fill" style={groupStyle}>
						<label className="bp3-button" style={labelStyle} htmlFor="manager">Создал</label>
						<input className={Classes.INPUT + ' ' + 'bp3-fill'}
							   value={get(orgStore, 'item.creator.fio', '')}
							   readOnly={true}
						/>
					</ControlGroup>
				</FormGroup>

				<div style={{maxHeight: '250px', height: 'auto', overflowY: 'auto'}}>
					<label className="bp3-label">Адреса:</label>
					{orgStore.item.addresses.map((a, i) => {
						return (
							<div className="bp3-control-group" style={groupStyle} key={i}>
								<div className="bp3-input-group bp3-fill">
									<input type="text" className="bp3-input" value={a.address} readOnly={true} />
									<div className="bp3-input-action">
										<button className="bp3-button bp3-minimal" onClick={() => orgStore.removeAddress(i)}>
											<span className="bp3-icon-cross"/>
										</button>
									</div>
								</div>
							</div>
						);
					})}

					<div className="bp3-control-group" style={groupStyle} key={'filter'}>
						<span className="bp3-button">Новый адрес</span>
						<div className="bp3-input-group bp3-fill">
							<SelectAddress highlightText={highlightText}/>
							<div className="bp3-input-action">
								<button className="bp3-button bp3-minimal" onClick={orgStore.addAddress}>
									<span className="bp3-icon-add"/>
								</button>
							</div>
						</div>
					</div>
				</div>

			</div>

			<div className={Classes.DIALOG_FOOTER}>
				<div className={Classes.DIALOG_FOOTER_ACTIONS}>
					<Button onClick={orgStore.update} intent="success">Сохранить</Button>
					<Button onClick={orgStore.closeEditDialog}>Закрыть</Button>
				</div>
			</div>

			<SearchClient
				isSearchOpen={isSearchOpen}
				setIsSearchOpen={setIsSearchOpen}
				highlightText={highlightText}
			/>
		</Dialog>
	);
}));

export default EditOrgDialogBP;