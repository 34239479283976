import React, { useState } from 'react';
import {
	Intent,
	Button,
	H5,
	Classes,
	ControlGroup,
	Dialog,
	FormGroup,
	Switch,
	TagInput,
	Label,
	NumericInput,
	Position,
	Checkbox,
	TextArea,
	Icon,
	HTMLSelect
} from "@blueprintjs/core";
import SearchClient from './master/SearchClient';
import SelectClient from './master/SelectClient';
import SelectAddress from './master/SelectAddress';
import SelectLocation from './master/SelectLocation';
import SelectExecutors from './master/SelectExecutors';
import { DateInput, TimePrecision } from "@blueprintjs/datetime";
import styled from "styled-components";
import MomentLocaleUtils from 'react-day-picker/moment'
import 'moment/locale/ru';
import get from 'lodash/get'
import highlightText from "../utils/highlightText";
import { observer } from 'mobx-react-lite';

const RLabel = styled.td`
	font-weight: bold;
	padding: 3px 5px;
	text-align: right;
	vertical-align: top;
`;

const RValue = styled.td`
	padding: 3px 5px;
`;

const EditOrderDialogBP = observer(({store = {}, ...props}) => {
	const [isSearchOpen, setIsSearchOpen] = useState(false);

	const labelStyle = {
		width: '150px',
		flexShrink: 0,
		flexGrow: 0,
	};

	const groupStyle = {
		marginBottom: '2px'
	};

	const orgForm = () => (
		<FormGroup>
			<ControlGroup className="bp3-fill" style={groupStyle}>
				<label className="bp3-button" style={labelStyle} htmlFor="client_title">Клиент</label>
				<SelectClient highlightText={highlightText}/>
			</ControlGroup>

			<ControlGroup className="bp3-fill" style={groupStyle}>
				<label className="bp3-button" style={labelStyle} htmlFor="org_name">Юр.лицо</label>
				<input className={Classes.INPUT + ' ' + 'bp3-fill'}
				       value={get(store.order.org, 'name', '')}
				       onChange={e => store.set('org.name', e.target.value)}
				       id='org_name'
				/>
			</ControlGroup>

			<ControlGroup className="bp3-fill" style={groupStyle}>
				<label className="bp3-button" style={labelStyle} htmlFor="org_head_name">Руководитель</label>
				<input className={Classes.INPUT + ' ' + 'bp3-fill'}
				       value={get(store.order.org, 'headName', '')}
				       onChange={e => store.set('org.headName', e.target.value)}
				       id='org_head_name'
				/>
			</ControlGroup>
			<ControlGroup className="bp3-fill" style={groupStyle}>
				<label className="bp3-button" style={labelStyle} htmlFor="org_head_position">Должность</label>
				<input className={Classes.INPUT + ' ' + 'bp3-fill'}
				       value={get(store.order.org, 'headPosition', '')}
				       onChange={e => store.set('org.headPosition', e.target.value)}
				       id='org_head_position'
				/>
			</ControlGroup>

			<ControlGroup className="bp3-fill" style={groupStyle}>
				<label className="bp3-button" style={labelStyle} htmlFor="org_inn">ИНН</label>
				<input className={Classes.INPUT + ' ' + 'bp3-fill'}
				       value={get(store.order.org, 'inn', '')}
				       onChange={e => store.set('org.inn', e.target.value)}
				       id='org_inn'
				/>
				<label className="bp3-button" style={labelStyle} htmlFor="org_kpp">КПП</label>
				<input className={Classes.INPUT + ' ' + 'bp3-fill'}
				       value={get(store.order.org, 'kpp', '')}
				       onChange={e => store.set('org.kpp', e.target.value)}
				       id='org_kpp'
				/>
			</ControlGroup>

			<ControlGroup className="bp3-fill" style={groupStyle}>
				<label className="bp3-button" style={labelStyle} htmlFor="org_ogrn">ОГРН</label>
				<input className={Classes.INPUT + ' ' + 'bp3-fill'}
				       value={get(store.order.org, 'ogrn', '')}
				       onChange={e => store.set('org.ogrn', e.target.value)}
				       id='org_ogrn'
				/>
				<label className="bp3-button" style={labelStyle} htmlFor="org_bik">БИК</label>
				<input className={Classes.INPUT + ' ' + 'bp3-fill'}
				       value={get(store.order.org, 'bik', '')}
				       onChange={e => store.set('org.bik', e.target.value)}
				       id='org_bik'
				/>
			</ControlGroup>

			<ControlGroup className="bp3-fill" style={groupStyle}>
				<label className="bp3-button" style={labelStyle} htmlFor="manager">Налогообложение</label>
				<HTMLSelect
					options={[
						{value: 0, label: 'Не определено'},
						{value: 1, label: 'УСН'},
						{value: 2, label: 'ОСНО'},
					]}
					value={get(store.order.org, 'taxation', 0)}
					onChange={e => store.set('org.taxation', parseInt(e.currentTarget.value))}
				/>
			</ControlGroup>
		</FormGroup>
	);

	const peopleForm = () => (
		<FormGroup>
			<ControlGroup className="bp3-fill" style={groupStyle}>
				<label className="bp3-button" style={labelStyle} htmlFor="people_name">ФИО</label>
				<input className={Classes.INPUT + ' ' + 'bp3-fill'}
				       value={get(store.order.people, 'name', '')}
				       onChange={e => store.set('people.name', e.target.value)}
				       id='people_name'
				/>
			</ControlGroup>
		</FormGroup>
	);

	const phonesForm = () => {
		const phones = (store.isPeople ? get(store.order.people, 'phones', []) : get(store.order.org, 'phones', []))
			.map(p => p.number.replace(/\D+/g, '').replace(/^.*(\d{3})(\d{3})(\d{2})(\d{2})$/, '+7 $1 $2-$3-$4'));

		return (
			<FormGroup>
				<ControlGroup className="bp3-fill" style={groupStyle}>
					<label className="bp3-button" style={labelStyle} htmlFor="phones">Телефоны {store.isPeople ? 'физ.лица' : 'юр.лица'}</label>
					<TagInput fill={true}
					          values={phones}
					          onAdd={store.addPhone}
					          onRemove={store.removePhone}
							  addOnBlur={true}
					/>
				</ControlGroup>
			</FormGroup>
		);
	};

	const emailsForm = () => {
		const emails = (store.isPeople ? get(store.order.people, 'emails', []) : get(store.order.org, 'emails', []))
			.map(e => e.email);

		return (
			<FormGroup>
				<ControlGroup className="bp3-fill" style={groupStyle}>
					<label className="bp3-button" style={labelStyle} htmlFor="emails">E-mail</label>
					<TagInput fill={true}
					          values={emails}
					          onAdd={store.addEmail}
					          onRemove={store.removeEmail}
					/>
				</ControlGroup>
			</FormGroup>

		);
	};

	const sourceForm = () =>
		<FormGroup>
			<ControlGroup className="bp3-fill" style={groupStyle}>
				<label className="bp3-button" style={labelStyle} htmlFor="emails">Источник</label>
				<HTMLSelect
					options={store.sources.map(i => ({value: i.id, label: i.title}))}
					value={store.isPeople ? get(store.order.people, 'source.id', 1) : get(store.order.org, 'source.id', 1)}
					onChange={e => store.isPeople ? store.set('people.source.id', e.target.value) : store.set('org.source.id', e.target.value)}
				/>
			</ControlGroup>
		</FormGroup>
	;

	const addressForm = () => {
		return (
			<FormGroup>
				<ControlGroup>
					<label className="bp3-button" style={labelStyle} htmlFor="address">Адрес</label>
					<SelectAddress/>
				</ControlGroup>
			</FormGroup>
		);
	};

	const locationsForm = () => {
		return (
			<FormGroup>
				<ControlGroup>
					<label className="bp3-button" style={labelStyle} htmlFor="address">Локация</label>
					<SelectLocation highlightText={highlightText}/>
				</ControlGroup>
			</FormGroup>
		);
	};

	const servicesForm = () => {
		return (
			<FormGroup>
				<Label>
					Услуги
					<Button minimal={true} icon='add' onClick={store.addService} />
				</Label>
				{store.order.services.map((s, i) => {
					const service = store.services.find(s2 => s2.id === s.id);

					return (
						<React.Fragment key={i}>
							<ControlGroup key={0}>
								<div className="bp3-select bp3-fill">
									<select value={service.id} onChange={e => store.setService(i, e.target.value)}>
										{store.services.map((se, j) => <option key={j} value={se.id}>{se.title}</option>)}
									</select>
								</div>

								<div className="bp3-select bp3-fill">
									<select value={s.servicePrice} onChange={e => store.setServicePrice(i, e.target.value)}>
										{service.servicePrices.map((p, k) => <option key={k} value={p.id}>{p.title}</option>)}
									</select>
								</div>

								<NumericInput value={s.count}
								              placeholder="Количество"
								              onValueChange={e => store.setServiceCount(i, isNaN(parseInt(e)) ? 0 : e)}
								              style={{width: '50px'}}
								              buttonPosition={Position.RIGHT}
								              min={0}
								/>

								<NumericInput value={Boolean(s.discount) ? s.discount : 0}
								              placeholder="Количество"
								              onValueChange={e => store.setServiceDiscount(i, e)}
								              style={{width: '85px'}}
								              buttonPosition={Position.RIGHT}
								              leftIcon="minus"
								              rightElement={<Icon icon="percentage" style={{color: '#5c7080', margin: '7px'}} />}
								              min={0}
								              max={100}
								              allowNumericCharactersOnly={true}
								/>

								<Button minimal={true} icon='delete' onClick={() => store.delService(i)} title="Удалить" />
							</ControlGroup>
						</React.Fragment>
					);
				})}
			</FormGroup>
		);
	};

	const extraServicesForm = () => {
		return (
			<FormGroup>
				<Label>
					Дополнительные услуги
				</Label>

				{store.extraServices.map((extraService, key) => {
					const extraServiceCount = store.order.extraServices.find(es => es.id === extraService.id);

					let value = 0;
					if (extraService.isSwitch) {
						value = extraServiceCount === undefined ? false : Boolean(extraServiceCount.value);
					} else {
						value = extraServiceCount === undefined ? 0 : (isNaN(parseInt(extraServiceCount.value)) ? 0 : parseInt(extraServiceCount.value));
					}

					return (
							<ControlGroup key={key} fill>
								<Label style={{width: 'calc(100% - 50px)', textDecoration: Boolean(value) ? 'initial' : 'line-through', paddingTop: '5px'}}>{extraService.title}</Label>
								{extraService.isSwitch ? (
									<Checkbox checked={value}
									          onChange={e => store.setExtraServiceValue(extraService.id, e.target.checked)}
									          alignIndicator="center"
									/>
								) : (
									<NumericInput value={value}
									              placeholder="Количество"
									              onValueChange={value => store.setExtraServiceValue(extraService.id, value)}
									              buttonPosition={Position.RIGHT}
									              min={0}
									              allowNumericCharactersOnly={true}
									              style={{width: '50px'}}
									/>
								)}
							</ControlGroup>
						);
				})}
			</FormGroup>
		);
	};

	const priceForm = () => {
		//let price = store.price;

		/*
		store.order.servicesCount.forEach(p => {
			price += p.count * p.price;
		});
		 */

		return (
			<FormGroup>
				<ControlGroup>
					<label className="bp3-button" style={labelStyle}>Стоимость</label>
					<div style={{textAlign: 'center', lineHeight: '30px', display: 'flex', flexDirection: 'row', margin: '0 10px'}}>
						{store.order.isCalculated ? (new Intl.NumberFormat('ru-RU').format(store.order.price)) : (
							<NumericInput value={store.order.price}
							              style={{width: '100px', display: 'inline-block', marginRight: '10px'}}
							              onValueChange={e => store.order.price = e}
							              buttonPosition="none"
							              min={0}
							              allowNumericCharactersOnly={true}
							/>
						)} руб
					</div>
					<Switch checked={store.order.isCash} label={store.order.isCash ? 'нал' : 'безнал'} onChange={e => store.order.isCash = e.target.checked} style={{flexGrow: 1}} />
					<Checkbox label="калькулятор" checked={store.order.isCalculated} onChange={store.setIsCalculated} />
				</ControlGroup>
			</FormGroup>
		);
	};

	const statusForm = () => {
		return (
			<FormGroup>
				<ControlGroup>
					<label className="bp3-button" style={labelStyle}>Статус</label>
					<div className="bp3-select bp3-fill">
						<select value={store.order.status.id} onChange={e => store.order.status.id = e.target.value}>
							{store.statuses.map((s, j) => <option key={j} value={s.id}>{s.title}</option>)}
						</select>
					</div>
				</ControlGroup>
			</FormGroup>
		);
	};

	const executorsForm = () => {
		return (
			<FormGroup>
				<ControlGroup className="bp3-fill" style={groupStyle}>
					<label className="bp3-button" style={labelStyle} htmlFor="executors">Исполнители</label>
					<SelectExecutors highlightText={highlightText}/>
				</ControlGroup>
			</FormGroup>
		);
	};

	const contractForm = () => {
		return (
			<FormGroup>
				<ControlGroup>
					<Switch checked={store.order.withContract} label={store.order.withContract ? 'с договором' : 'без договора'} onChange={e => { store.order.withContract = e.target.checked; if(e.target.checked) store.getNumber(); }} />
					{/*
					<div className="bp3-select bp3-fill" style={{marginLeft: '20px'}}>
						<select className={store.order.withContract ? '' : 'bp3-disabled'} disabled={store.order.withContract ? '' : 'disabled'} value={get('store.order.contract.type.id', 1)} onChange={e => store.order.contract.type.id = e.target.value}>
							{store.contractTypes.map((s, j) => <option key={j} value={s.id}>{s.title}</option>)}
						</select>
					</div>
					*/}
					{store.order.withContract ? (
						<div className="bp3-select bp3-fill" style={{marginLeft: '20px'}}>
							<select value={store.order.contract.id} onChange={store.setContract}>
								<option key={999} value={0}>Новый договор</option>
								{store.contracts.map((c, key) => <option key={key} value={c.id}>{c.number} от {new Date(c.startedAt).toLocaleFormat('%d.%m.%Y')}</option>)}
							</select>
						</div>
					) : null}
				</ControlGroup>
				{store.order.withContract ? (
					<>
						<ControlGroup className="bp3-fill" style={{marginBottom: '5px'}}>
							<label className="bp3-button" style={labelStyle} htmlFor="title">Тип</label>
							<div className='bp3-select  bp3-fill'>
								<select value={get(store.order.contract, 'type.id', 5)} onChange={store.setContractType} disabled={store.order.contract.id > 0}>
									{store.contractTypes.map((t, key) => <option value={t.id} key={key}>{t.title}</option>)}
								</select>
							</div>
						</ControlGroup>

						<ControlGroup style={{marginBottom: '5px'}}>
							<label className="bp3-button" style={labelStyle} htmlFor="title">Статус</label>
							<div className='bp3-select bp3-fill'>
								<select value={get(store.order.contract, 'status.id', 1)} onChange={e => store.setContractProperty('status.id', e.target.value)} disabled={store.order.contract.id > 0}>
									{store.contractStatuses.map((s, key) => <option value={s.id} key={key}>{s.title}</option>)}
								</select>
							</div>
						</ControlGroup>

						<ControlGroup style={{marginBottom: '5px'}}>
							<label className="bp3-button" style={labelStyle} htmlFor="title">Номер</label>
							<input className='bp3-input bp3-fill' value={get(store.order.contract, 'number', '')} onChange={e => store.setContractProperty('number', e.target.value)} disabled={store.order.contract.id > 0} />
						</ControlGroup>

						<ControlGroup style={{marginBottom: '5px'}}>
							<label className="bp3-button" style={labelStyle} htmlFor="title">Дата</label>
							<DateInput
								fill
								locale="ru"
								localeUtils={MomentLocaleUtils}
								value={typeof get(store.order.contract, 'startedAt', '') === 'string' ? new Date(store.order.contract.startedAt) : store.order.contract.startedAt}
								formatDate={d => d.toLocaleFormat('%d.%m.%Y')}
								// timePrecision={TimePrecision.MINUTE}
								onChange={e => store.setContractProperty('startedAt', e)}
								parseDate={str => new Date(str.replace(/\D+/g, '').replace(/^(\d{2})(\d{2})(\d{4})$/, '$3-$2-$1')) }
								popoverProps={{ position: Position.BOTTOM }}
								disabled={store.order.contract.id > 0}
							/>
						</ControlGroup>

						<ControlGroup style={{marginBottom: '5px'}}>
							<label className="bp3-button" style={labelStyle} htmlFor="title">Срок</label>
							<NumericInput value={get(store.order.contract, 'months', 9)}
										  //style={{width: '100px', display: 'inline-block', marginRight: '10px'}}
										  onValueChange={e => store.setContractProperty('months', e)}
										  buttonPosition="right"
										  min={0}
										  allowNumericCharactersOnly={true}
										  //fill
										  disabled={store.order.contract.id > 0}
							/>
						</ControlGroup>

						<ControlGroup>
							<label className="bp3-button" style={labelStyle} htmlFor="title">Информация</label>
							<TextArea value={get(store.order.contract, 'info', '')}
									  onChange={e => store.setContractProperty('info', e.target.value)}
									  fill
									  intent={Intent.PRIMARY}
									  disabled={store.order.contract.id > 0}
							/>
						</ControlGroup>
					</>
				) : null}
			</FormGroup>
		);
	};

	const dtWorkForm = () => {
		return (
			<FormGroup>
				<ControlGroup className="" style={{...groupStyle, width: '200px !important'}}>
					<label className="bp3-button" style={labelStyle} htmlFor="executors">Дата</label>
					<DateInput
						style={{width: '200px'}}
						locale="ru"
						// localeUtils={LocaleUtils}
						closeOnSelection={false}
						localeUtils={MomentLocaleUtils}
						value={store.order.dtWork}
						formatDate={d => d.toLocaleFormat('%H:%M %d.%m.%Y')}
						timePrecision={TimePrecision.MINUTE}
						onChange={e => store.set('dtWork', e)}
						parseDate={str => new Date(str.replace(/\D+/g, '').replace(/^(\d{2})(\d{2})(\d{2})(\d{2})(\d{4})$/, '$5-$4-$3T$1:$2:00')) }
						popoverProps={{ position: Position.BOTTOM }}
					/>

				</ControlGroup>
				<label className="bp3-control bp3-checkbox" style={{marginBottom: '0 !important'}}>
					<input type="checkbox" checked={store.order.isMass} onChange={e => store.set('isMass', e.target.checked)} />
					<span className="bp3-control-indicator" />
					в течении дня
				</label>
			</FormGroup>
		);
	};

	const commentForm = () => (
		<FormGroup>
			<Label>Комментарий</Label>
			<TextArea value={store.order.comment}
			          onChange={e => store.set('comment', e.target.value)}
			          fill
			          intent={Intent.PRIMARY}
			/>
		</FormGroup>
	);

	const result = () => {
		if (store.order.results === undefined) {
			return null;
		}

		const results = store.order.results;

		const tables = results.map((result, key) => {
			const materials = JSON.parse(result.materials);

			return (
				<table cellSpacing="0" width="100%" key={key}>
					<tbody>
					<tr>
						<td colSpan={2}><H5>Комментарии от мастера {get(result, 'executor.fio', '')}</H5></td>
					</tr>

					{result.guarantee ? <tr>
						<RLabel>Гарантийный срок:</RLabel>
						<RValue>{result.guarantee}</RValue>
					</tr> : null}

					{result.rePrice ? <tr>
						<RLabel>Стоимость повторной обработки:</RLabel>
						<RValue>{result.rePrice}</RValue>
					</tr> : null}

					{result.recomendations.trim().length > 0 ? <tr>
						<RLabel>Рекомендации клиенту:</RLabel>
						<RValue>{result.recomendations.split("\n").map((r, k) => <div key={k}>{r}</div>)}</RValue>
					</tr> : null}

					{result.comment.trim().length > 0 ? <tr>
						<RLabel>Комментарий:</RLabel>
						<RValue>{result.comment.split("\n").map((r, k) => <div key={k}>{r}</div>)}</RValue>
					</tr> : null}

					{materials.length > 0 ? <tr>
						<RLabel>Материалы:</RLabel>
						<RValue>
							{materials.map((m, k) => {
								const material = store.materials.find(m2 => m2.id === m.id);

								if (material === undefined) {
									return null;
								}

								return <div key={k}>{material.title}{Boolean(material.unit) ? ` (${material.unit})` : ''} x {m.count}</div>
							})}
						</RValue>
					</tr> : null}

					{get(result, 'files', []).length > 0 ? (
						<tr>
							<RLabel>Загруженные файлы:</RLabel>
							<RValue>
								{result.files.map((f, k) => (
									<a className='bp3-button' key={k} href={`/files/${result.id}/${f}`} target='_blank' style={{display: 'block', marginTop: '5px'}}>{f}</a>
								))}
							</RValue>
						</tr>
					) : null}
					</tbody>
				</table>
			);
		});

		return <div>{tables}</div>;
	};

	const history = () => {
		if (get(store.order, 'orderStatusHistories', []).length === 0) {
			return null;
		}

		return (
			<div>
				<H5>Историй изменения статусов</H5>
				{store.order.orderStatusHistories.map((h, k) => (
					<div key={k}>
						{new Date(h.updateDate).toLocaleFormat('%H:%M %d.%m.%Y')} {h.toStatus.title} ({h.user.fio})
					</div>
				))}
			</div>
		);
	};

	const orderPhonesForm = () =>
		<FormGroup>
			<ControlGroup className="bp3-fill" style={groupStyle}>
				<label className="bp3-button" style={labelStyle} htmlFor="phones">Телефоны заказа</label>
				<TagInput fill={true}
						  values={store.order.phones.map(i => i.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '+7 ($1) $2-$3-$4'))}
						  onAdd={store.addOrderPhone}
						  onRemove={store.delOrderPhone}
						  addOnBlur={true}
				/>
			</ControlGroup>
		</FormGroup>
	;

	const cashierForm = () => {
		if (!store.order.isCash || store.order.executors.length < 2) {
			return null;
		}

		return (
			<FormGroup>
				<ControlGroup className="bp3-fill" style={groupStyle}>
					<label className="bp3-button" style={labelStyle} htmlFor="phones">Деньги у</label>
					<div className="bp3-select .modifier">
						<select value={store.order.cashier === null ? 0 : store.order.cashier.id} onChange={store.setCashier}>
							<option value={0} key={666}>Не назначен</option>
							{store.order.executors.map((i, key) => <option value={i.id} key={key}>{i.fio}</option>)}
						</select>
					</div>
				</ControlGroup>
			</FormGroup>
		);
	};

	return (
		<Dialog isOpen={store.isEditDialogOpen} onClose={store.closeEditDialog} title={store.editDialogTitle} usePortal={true} style={{width: '60%'}} canEscapeKeyClose={false} canOutsideClickClose={false}>
			<div className={Classes.DIALOG_BODY}>
				<FormGroup>
					<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
						<Switch checked={store.isPeople} label={store.isPeople ? 'Физ.лицо' : 'Юр.лицо'} onChange={store.setIsPeople} style={{flexGrow: 1}} />
						<Button className="bp3-button" small={true} text="Найти клиента" onClick={() => setIsSearchOpen(true)} fill={false} />
					</div>
				</FormGroup>

				{store.isPeople ? peopleForm() : orgForm()}

				{phonesForm()}
				{emailsForm()}
				{sourceForm()}
				{addressForm()}
				{locationsForm()}
				{dtWorkForm()}
				{orderPhonesForm()}
				{servicesForm()}
				{extraServicesForm()}
				{priceForm()}
				{statusForm()}
				{executorsForm()}
				{cashierForm()}
				{contractForm()}
				{commentForm()}
				{result()}
				{history()}
			</div>

			<div className={Classes.DIALOG_FOOTER}>
				<div className={Classes.DIALOG_FOOTER_ACTIONS}>
					<Button onClick={store.update} intent="success">Сохранить</Button>
					<Button onClick={store.closeEditDialog}>Закрыть</Button>
				</div>
			</div>

			<SearchClient
				isSearchOpen={isSearchOpen}
				setIsSearchOpen={setIsSearchOpen}
				highlightText={highlightText}
			/>
		</Dialog>
	);
});

export default EditOrderDialogBP;
