import {observable, action, autorun, computed, toJS, reaction} from 'mobx';
import EditOrderDialogBP from "../components/EditOrderDialogBP";
import React from "react";
import * as md5 from "md5";
import get from 'lodash/get'
import set from 'lodash/set'
import { defaultStore } from './useDefaultStore';

class OrderStore {
	@observable orders = [];
	@observable isEditDialogOpen = false;
	@observable statuses = [];
	@observable executors = [];
	@observable currentExecutor = null;
	@observable contracts = [];

	@observable organizationsDadata = [];
	@observable organizationsDB = [];

	@observable peoples = [];

	@observable sources = [];
	@observable services = [];
	@observable extraServices = [];
	@observable clients = [];
	@observable contractStatuses = [];
	@observable contractTypes = [];
	@observable materials = [];

	@observable addressesDadata = [];
	@observable addressesDB = [];

	@observable now = new Date();
	@observable year = new Date();

	terms = ['заказ', 'заказа', 'заказов'];
	onCreateButtonTitle = "Новый заказ";
	titleField = "id";
	headers = [
		{id: 'contract.number', label: 'Договор', sort: i => Boolean(i.contract) ? i.contract.number: ''},
		{id: o => !Boolean(o.people) ? (!Boolean(o.org.client) ? o.org.name : o.org.client.title) : o.people.name, label: 'Клиент', sort: o => (!Boolean(o.people) ? (!Boolean(o.org.client) ? o.org.name : o.org.client.title) : o.people.name).toLowerCase().trim()},
		{id: 'status.title', label: 'Статус', sort: i => Boolean(i.status) ? i.status.title: ''},
		{id: 'price', label: 'Стоимость', func: price => new Intl.NumberFormat('ru-RU').format(price) + ' руб.', sort: i => parseInt(i.price)},
		{id: o => new Date(o.createdAt).toLocaleFormat("%H:%M %d.%m.%Y"), label: 'Создан', sort: i => new Date(i.createdAt)},
		{id: o => new Date(o.dtWork).toLocaleFormat(o.isMass ? "%d.%m.%Y" : "%H:%M %d.%m.%Y"), label: 'Дата работ', sort: i => new Date(i.dtWork)},
	];

	defaultOrder = {
		id: 0,
		price: 0,
		services: [],
		extraServices: [],
		isCash: true,
		dtWork: null,
		comment: '',
		contract: {
			id: 0,
			type: {
				id: 0,
				title: ''
			},
			status: {
				id: 0,
				title: ''
			},
			info: '',
			number: '',
			months: 12,
			startedAt: new Date(),
		},
		status: {
			id: 1
		},
		executors: [],
		people: null,
		org: null,
		address: null,
		location: null,
		withContract: false,
		isCalculated: true,
		orderStatusHistories: [],
		phones: [],
		cashier: null,
		source: {id: 1},
		isMass: false,
	};

	@observable order = this.defaultOrder;
	get order() { return this.order; };
	set order(order) { this.order = order };

	@observable isPeople = false;

	timer = null;

	@observable suggestOrg1 = [];
	@observable suggestOrg2 = [];
	@observable suggestPeople = [];

	@observable suggestAddress1 = [];
	get suggestAddress1() { return this.suggestAddress1 };
	set suggestAddress1(addresses) { this.suggestAddress1 = addresses };

	@observable suggestAddress2 = [];

	@computed get suggestAddress() {
		const temps = this.suggestAddress1.map(a => a.address);
		return this.suggestAddress1
			.concat(this.suggestAddress2.filter(a => !temps.includes(a.address)))
			.sort((a, b) => {
				if ((a.id > 0 && b.id === 0) || (a.id === 0 && b.id > 0)) {
					if (a.id > 0) return -1;
					if (b.id > 0) return 1;
					return 0;
				}

				const title1 = a.address.toLowerCase();
				const title2 = b.address.toLowerCase();
				if (title1 > title2) return 1;
				if (title1 < title2) return -1;
				return 0;
			})
	}

	constructor(rootStore) {
		this.mainStore = rootStore.mainStore;
		this.calendarStore = rootStore.calendarStore;
		this.order = this.defaultOrder;
	}

	@computed get suggestOrg() {
		const inns = this.suggestOrg1.map(i => `${i.inn}:${i.kpp}`);

		return this.suggestOrg1
			.map(item => ({...item, isNew: false}))
			.concat(this.suggestOrg2.filter(i => !inns.includes(`${i.data.inn}:${i.data.kpp}`)).map(i => ({...i, isNew: true})))
			.sort((a, b) => {
				const title1 = a.isNew ? a.value : a.name;
				const title2 = b.isNew ? b.value : b.name;
				if (title1 > title2) return 1;
				if (title1 < title2) return -1;
				return 0;
			});
	}

	@action clear = () => {
		clearInterval(this.timer);

		this.orders = [];
		this.services = [];
		this.sources = [];
		this.contractStatuses = [];
		this.contractTypes = [];
		this.order = this.defaultOrder;
		this.statuses = [];
		this.executors = [];
		this.currentExecutor = null;
		this.contracts = [];
		this.clients = [];
		this.materials = [];
		this.x5Contracts = [];
		this.x5Addresses = [];
	};

	@action fetch = () => {
		this.mainStore.ajax('/api/orders', {}, data => {
			this.orders = data.orders;
			this.services = data.services;
			this.extraServices = data.extraServices;
			this.contractStatuses = data.contractStatuses;
			this.contractTypes = data.contractTypes;
			this.clients = data.clients;
			this.statuses = data.statuses;
			this.executors = data.executors;
			this.contractTypes = data.contractTypes;
			this.materials = data.materials;
			set(this.defaultOrder, 'contract.type', data.contractTypes.find(t => t.id === 5));
			set(this.defaultOrder, 'contract.status', data.contractStatuses.find(t => t.id === 1));
		});

		this.mainStore.apiFetchItems('/api/sources?isActive=true', data => this.sources = data.sort((a, b) => {
			if (a.title.toLowerCase() === b.title.toLowerCase()) {
				return 0;
			}

			return a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1;
		}));
	};

	@action fetchLibs = () => {
		this.mainStore.ajax('/api/libs', {}, data => {
			// this.sources = data.sources;
			this.services = data.services;
			this.extraServices = data.extraServices;
			this.contractStatuses = data.contractStatuses;
			this.contractTypes = data.contractTypes;
			// this.clients = data.clients;
			// this.statuses = data.statuses;
			// this.executors = data.executors;
			// this.contractTypes = data.contractTypes;
			set(this.defaultOrder, 'contract.type', data.contractTypes.find(t => t.id === 5));
			set(this.defaultOrder, 'contract.status', data.contractStatuses.find(t => t.id === 1));
		});
	};

	@action create = () => {
		// this.isPeople = true;
		// this.order = {...this.defaultOrder, dtWork: new Date().toISOString()};

		this.order = {
			...this.defaultOrder,
			dtWork: new Date(),
			extraServices: this.extraServices.filter(es => es.isDefault).map(es => ({...es, value: es.isSwitch ? true : 1})),
			services: this.services.filter(s => s.isDefault).map(s => ({
				id: s.id,
				servicePrice: s.servicePrices.length === 0 ? 0 : s.servicePrices[0].id,
				count: 1,
				discount: 0,
			})),
		};
		this.isEditDialogOpen = true;
	};


	@action edit = (id) => {
		this.mainStore.ajax('/api/order/' + id + (this.services.length === 0 ? '/1' : ''), {}, data => {
			if (data.services !== undefined) {
				this.services = data.services;
				this.extraServices = data.extraServices;
				this.materials = data.materials;
				this.statuses = data.statuses;
				this.executors = data.executors;
				this.contractTypes = data.contractTypes;
				set(this.defaultOrder, 'contract.type', data.contractTypes.find(t => t.id === 5));
				set(this.defaultOrder, 'contract.status', data.contractStatuses.find(t => t.id === 1));
			}

			this.order = {
				...data.order,
				withContract: Boolean(data.order.contract),
				dtWork: new Date(data.order.dtWork),
				phones: data.order.phones.split(',').filter(i => /^\d{10}$/.test(i))
			};

			this.suggestAddress1 = data.addresses;

			if (data.order.org !== null) {
				this.contracts = get(data, 'order.org.contracts', []);
			}

			if (data.order.people !== null) {
				this.contracts = get(data, 'order.people.contracts', []);
			}

			if (get(data, 'order.contract.id', 0) > 0 && this.contracts.find(c => c.id === get(data, 'order.contract.id', 0)) === undefined) {
				this.contracts.push(data.order.contract);
			}

			if (get(data, 'order.contract.id', 0) === 0) {
				set(this.order, 'contract', this.defaultOrder.contract);
				this.getNumber();
			}
			this.isPeople = Boolean(data.order.people);

			if (data.order.address !== null) {
				this.locations = data.order.address.locations;
			}

			this.isEditDialogOpen = true;
		});
	};

	@action update = () => {
		if (!this.isPeople && get(this.order, 'org.taxation', 0) === 0) {
			this.mainStore.addError('Не выбран типа налогообложения!');
			return;
		}

		this.mainStore.ajax('/api/order/update/' + this.order.id,
			{
				...this.order,
				dtWork: this.order.dtWork.toISOString(),
				services: JSON.stringify(this.order.services),
				extraServices: JSON.stringify(this.order.extraServices),
				phones: this.order.phones.join(',')
			}, data => {
			this.isEditDialogOpen = false;
			if (this.calendarStore.isLoaded) {
				this.calendarStore.fetch();
			} else {
				this.fetch();
			}
		});
	};

	@action delete = (id) => this.mainStore.ajax('/api/order/delete/' + id, {}, this.fetch);

	@action set = (field, value) => {
            console.log(field);
            console.log(value);
		if (field === 'dtWork') {
			this.order.dtWork = new Date(value);
		}

		if (/^org\./.test(field) && !Boolean(this.order.org)) {
			this.order.org = {
				id: 0,
				bankName: "",
				bik: "",
				checkAccount: "",
				corrAccount: "",
				emails: [],
				headName: '',
				headPosition: '',
				inn: '',
				isActive: true,
				kpp: '',
				legalAddress: '',
				name: '',
				ogrn: '',
				phones: [],
				client: {
					id: 0,
					title: ''
				},
				source: {
					id: 1
				},
				taxation: 0
			};
		}

		if (/^people\./.test(field) && !Boolean(this.order.people)) {
			this.order.people = {
				id: 0,
				name: '',
				phones: [],
				emails: [],
				source: {
					id: 1
				}
			}
		}

		// eval('this.order.' + field + ' = value');
		set(this.order, field, value);
	};

	@action removeExecutor = (id) => {
		const index = this.order.executors.findIndex(s => parseInt(s.id) === parseInt(id));

		if (index !== -1) {
			this.order.executors.splice(index, 1);
		}
	};

	@action addExecutor = () => {

		/*
		if (this.order.executors.find(id => parseInt(id.id) === parseInt(this.currentExecutor)) === undefined) {
			this.order.executors.push({id: this.currentExecutor});
		}

		 */
	};

	@action setCurrentExecutor = (id) => {
		this.currentExecutor = parseInt(id);
	};

	@action findOrg = needle => {
		if (needle.length >= 2) {
			clearTimeout(this.timer);
			this.timer = setTimeout(() => {
				this.mainStore.ajax('/api/clients/search', {isPeople: this.isPeople, needle: needle}, data => {
					if (this.isPeople) {
						this.suggestPeople = data.peoples.sort((a, b) => {
							if (a.name > b.name) return 1;
							if (a.name < b.name) return -1;
							return 0;
						});
					} else {
						this.suggestOrg1 = data.orgs;
					}
				});

				if (!this.isPeople) {
					this.mainStore.startLoading();
					fetch('https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party', {
						method: 'POST',
						mode: 'cors',
						headers: {
							'Content-Type': 'application/json',
							'Authorization': 'Token ' + this.mainStore.dadataToken
						},
						body: JSON.stringify({
							query: needle,
							count: 10
						})
					})
						.then(res => res.json())
						.then(data => {
							this.suggestOrg2 = data.suggestions;
						})
						.catch(alert)
						.finally(() => {
							this.mainStore.stopLoading();
						});
				}
			}, 500);
		}
	};

	@action findAddress = needle => {
		clearTimeout(this.timer);
		if (needle.length >= 2) {
			this.timer = setTimeout(() => {
				const dadataToken = this.mainStore.dadataToken;
				this.mainStore.startLoading();
				fetch('https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address', {
					method: 'POST',
					mode: 'cors',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': 'Token ' + dadataToken
					},
					body: JSON.stringify({
						query: needle,
						count: 10,
						locations_boost: [
							{kladr_id: 77},
							//{kladr_id: 78},
							//{kladr_id: 47},
						]
					})
				})
					.then(res => res.json())
					.then(data => {
						this.suggestAddress2 = data.suggestions.map(s => {
							return {
								id: 0,
								address: s.value,
								locations: [],
							};
						});
					})
					.catch(alert)
					.finally(() => {
						this.mainStore.stopLoading();
					});
			}, 500);
		}
	};

	@computed get orgsOptions() {
		if (this.order.organization.isNew) {
			return this.organizationsDadata.map((o, i) => {
				return {
					value: i,
					label: `${o.data.name.full_with_opf} (ИНН: ${o.data.inn})`
				}
			});
		} else {
			return this.organizationsDB.map(o => {
				return {
					value: o.id,
					label: `${o.name} (ИНН: ${o.inn})`
				}
			});
		}
	}

	@computed get orgValue() {
		if (this.order.organization.isNew) {
			const o = this.organizationsDadata[this.order.organization.id];
			return o === undefined ? null : {
				value: o.id,
				label: `${o.data.name.full_with_opf} (ИНН: ${o.data.inn})`
			};
		} else {
			const o = this.organizationsDB.find(o => o.id === this.order.organization.id);
			return o === undefined ? null : {
				value: o.id,
				label: `${o.name} (ИНН: ${o.inn})`
			}
		}
	}

	@action setOrg = (value) => {
		const o = this.order.organization.isNew ? this.organizationsDadata[value.value] : this.organizationsDB.find(o => o.id === value.value);
		this.order.organization = {...this.order.organization, ...o, id: value.value, source: this.defaultOrder.source};
	};

	@action findPeople = needle => {
		if (needle.length < 3) {
			return;
		}

		clearTimeout(this.timer);

		this.timer = setTimeout(() => {
			this.mainStore.ajax('/api/people/find', {needle: needle}, data => {
				this.peoples = data.peoples;
			});
		}, 500);
	};

	@computed get peoplesOptions() {
		return this.peoples.map(p => {
			return {
				value: p.id,
				label: p.name
			};
		});
	}

	@computed get peopleValue() {
		const p = this.peoples.find(p => p.id === this.order.people.id);

		if (p !== undefined) {
			return {
				value: p.id,
				label: p.name
			};
		}
	}

	@action setPeople = (value) => {
		const p = this.peoples.find(p => p.id === value.value);
		p.phones = p.phones.slice().map(p => {
			return {number: p.number.replace(/\D+/, '').replace(/(\d)(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 $2 $3-$4-$5')}
		});
		this.order.people = toJS({...this.order.people, ...p, source: this.defaultOrder.source});
	};

	@computed get peoplePhones() {
		return this.order.people.phones.slice().map(p => p.number.replace(/(\d)(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 $2 $3-$4-$5')).join("\n");
	}

	@action setPeoplePhones = (e) => {
		this.order.people.phones = e.target.value.split("\n").map(p => {
			return {number: p}
		});
	};

	@computed get peopleEmails() {
		return this.order.people.emails.slice().map(e => e.email).join("\n");
	}

	@action setPeopleEmails = (e) => {
		this.order.people.emails = e.target.value.split("\n").map(em => {
			return {email: em}
		})
	};

	@computed get addressOptions() {
		const addresses = this.order.address.isNew ? this.addressesDadata : this.addressesDB;

		return addresses.map((a, i) => {
			return {
				value: this.order.address.isNew ? i : a.id,
				label: a.address
			}
		});
	}

	@computed get addressValue() {
		if (this.order.address.isNew) {
			const index = toJS(this.addressesDadata).findIndex(a => a.address === toJS(this.order.address.address));
			if (index !== undefined && index !== -1) {
				return {
					value: index,
					label: this.addressesDadata[index].address
				}
			}
		} else {
			const {id, address} = toJS(this.order.address);
			return {
				value: id,
				label: address
			}
		}
	}

	@action setAddress = e => {
		this.order.address = {...this.order.address, id: e.value, address: e.label};
	};

	/*
	@action addService = e => {
		const ids = this.order.services.map(s => s.id);
		const service = this.services.find(s => ids.indexOf(s.id) === -1);
		if (service !== undefined) {
			this.order.services.push({...service, count: 0});
		}
	};
	 */

	@action setCountForService = (id, count) => {
		const index = toJS(this.order.services).findIndex(s => s.id === id);

		if (index !== -1) {
			this.order.services[index].count = count;
		}
	};

	@action getPriceForService = service => {
		if (service.count === undefined) {
			return null
		}
		const count = Math.abs(service.count);

		const cell = service.priceCells.find(c => c.from === c.to ? count === Math.abs(c.from) : count >= c.from && count < c.to);
		return Math.abs(cell === undefined ? service.price : cell.price);
	};

	@action getTotalPriceForService = service => {
		if (service.count === undefined) {
			return null;
		}
		const count = Math.abs(service.count);
		const cell = service.priceCells.find(c => c.from === c.to ? count === Math.abs(c.from) : count >= c.from && count < c.to);
		//return cell === undefined ? Math.floor(service.price * count) : Math.floor(cell.price * count);
		return Math.abs(cell === undefined ? service.price : cell.price);
	};

	@action changeService = (from, to) => {
		const fromServiceIndex = toJS(this.order.services).findIndex(s => s.id === from);
		const toService = this.services.find(s => s.id === to);

		if (from !== -1 && to !== undefined) {
			this.order.services[fromServiceIndex] = {...toService, count: this.order.services[fromServiceIndex].count}
		}
	};

	@action totalPrice() {
		if (this.order.isCalculated) {
			let total = 0;

			this.order.services.forEach(s => {
				total += this.getTotalPriceForService(s);
			});

			return total;
		} else {
			return this.order.price;
		}
	}

	@action setPrice = e => {
		if (!this.order.isCalculated) {
			this.order.price = e.target.value;
		}
	};

	@action setWithContract = e => {
		if (e.target.checked) {
			this.mainStore.ajax('/api/contract/find', {people: this.order.people.id, org: this.order.organization.id}, data => {
				this.contracts = data.contracts;
			});
		}
		this.order.contract.withContract = e.target.checked;
	};

	@action setIsPeople = e => {
		if (e.target.checked) {
			this.suggestOrg1 = [];
			this.suggestOrg2 = [];
			this.order.org = null;
		} else {
			this.order.people = null;
		}
		this.suggestAddress1 = [];
		this.suggestAddress2 = [];
		this.isPeople = e.target.checked;
	};

	@action openEditDialog = () => this.isEditDialogOpen = true;
	@action closeEditDialog = () => {
		this.isEditDialogOpen = false;
		this.suggestAddress1 = [];
		this.suggestAddress2 = [];
	};

	@computed get items() {
		return this.orders;
	}

	@computed get editDialogTitle() {
		return this.order.id === 0 ? 'Новый заказ' : `Изменение заказа №${this.order.id}`;
	};

	@computed get editDialog() {
		return <EditOrderDialogBP/>;
	}

	@action addPhone = phone => {
		try {
			if (phone[0].replace(/\D+/g, '').length < 10) {
				this.mainStore.addError(`Некорректный номер телефона (меньше 10 цифр): ${phone[0]}`);
				return false;
			}

			if (this.isPeople) {
				if (this.order.people === undefined || !Boolean(this.order.people)) {
					this.order.people = {
						phones: []
					};
				}

				if (!Boolean(this.order.people.phones)) {
					this.order.people.phones = [];
				}

				this.order.people.phones.push({
					id: 0,
					number: phone[0].replace('/\D+/g', '').replace(/^.*(\d{10})$/, '$1')
				});

			} else {
				if (!Boolean(this.order.org)) {
					this.order.org = {
						phones: []
					};
				}

				if (!Boolean(this.order.org.phones)) {
					this.order.org.phones = [];
				}

				this.order.org.phones.push({
					id: 0,
					number: phone[0].replace('/\D+/g', '').replace(/^.*(\d{10})$/, '$1')
				});
			}
			return true;
		} catch (e) {
			this.mainStore.addError(`Некорректный номер телефона: ${phone[0]}`);
			return false;
		}
	};

	@action removePhone = (phone, index) => {
		try {
			if (this.isPeople) {
				this.order.people.phones.splice(index, 1);
			} else {
				this.order.org.phones.splice(index, 1);
			}
		} catch (e) {

		}
	};

	@action addEmail = email => {
		try {
			if (email[0].trim().length < 5) {
				return;
			}

			if (this.isPeople) {
				if (!Boolean(this.order.people)) {
					this.order.people = {
						emails: []
					};
				}

				if (!Boolean(this.order.people.emails)) {
					this.order.people.emails = [];
				}

				this.order.people.emails.push({
					id: 0,
					email: email[0].trim()
				});
			} else {
				if (!Boolean(this.order.org)) {
					this.order.org = {
						emails: []
					};
				}

				if (!Boolean(this.order.org.emails)) {
					this.order.org.emails = [];
				}

				this.order.org.emails.push({
					id: 0,
					email: email[0].trim()
				});
			}
			return true;
		} catch (e) {
			return false;
		}
	};

	@action removeEmail = (email, index) => {
		try {
			if (this.isPeople) {
				this.order.people.emails.splice(index, 1);
			} else {
				this.order.org.emails.splice(index, 1);
			}
		} catch (e) {

		}
	};

	@observable locations = [];
	get locations() {
		return this.locations.sort((a, b) => {
			const title1 = a.title.toLowerCase();
			const title2 = b.title.toLowerCase();
			if (title1 > title2) return 1;
			if (title1 < title2) return -1;
			return 0;
		});
	}
	set locations(locations) { this.locations = locations }

	@action addService = () => {
		this.order.services.push({
			id: this.services[0].id,
			servicePrice: this.services[0].servicePrices.length === 0 ? 0 : this.services[0].servicePrices[0].id,
			count: 1,
			discount: 0,
		});
	};

	@action delService = (i) => this.order.services.splice(i, 1);
	@action setService = (i, id) => {
		const service = this.services.find(s => s.id === parseInt(id));

		this.order.services[i] = {
			id: service.id,
			servicePrice: service.servicePrices.length === 0 ? 0 : service.servicePrices[0].id,
			count: 1,
			discount: 0,
		};
	};
	@action setServicePrice = (i, id) => this.order.services[i].servicePrice = parseInt(id);
	@action setServiceCount = (i, count) => this.order.services[i].count = parseInt(count);
	@action setExtraCount = (i, ei, count) => this.order.services[i].extraServices[ei].count = parseInt(count);
	@action setServiceDiscount = (i, discount) => this.order.services[i].discount = discount > 100 ? 100 : discount;

	calculatePrice = () => {
		if (this.order.isCalculated) {
			let price = 0;
			this.order.services.forEach(service => {
				try {
					const service2 = this.services.find(s => s.id === service.id);
					const servicePrice = service2.servicePrices.find(sp => sp.id === service.servicePrice) !== undefined ? service2.servicePrices.find(sp => sp.id === service.servicePrice) : service2.servicePrices[0];

					if (servicePrice !== undefined) {
						const x = service.count;
						const discount = Boolean(service.discount) ? service.discount : 0;
						price += Math.floor(eval(servicePrice.formula) * (1 - discount / 100));
					}
				} catch (e) {

				}
			});

			this.order.extraServices.forEach(extraService => {
				try {
					const extraService2 = this.extraServices.find(es => es.id === extraService.id);
					if (extraService2.isSwitch && extraService.value === true) {
						price += extraService2.price;
					}
					if (!extraService2.isSwitch) {
						price += extraService2.price * extraService.value;
					}
				} catch (e) {

				}
			});

			this.order.price = price;
		}
	};

	@action getExtraServiceValue = id => {
		const extraService = this.extraServices.find(es => es.id === id);
		const extraServiceCount = this.order.extraServices.find(es => es.id === id);

		if (extraService.isSwitch) {
			return extraServiceCount === undefined ? false : Boolean(extraServiceCount.value);
		} else {
			return extraServiceCount === undefined ? 0 : (isNaN(parseInt(extraServiceCount.value)) ? 0 : parseInt(extraServiceCount.count));
		}
	};

	@action setExtraServiceValue = (id, value) => {
		const extraServiceIndex = this.order.extraServices.findIndex(es => es.id === id);

		if (extraServiceIndex === -1) {
			this.order.extraServices.push({id, value});
		} else {
			this.order.extraServices[extraServiceIndex].value = value;
		}
	};

	@action setIsCalculated = e => {
		/*
		if (e.target.checked) {
			this.calculatePrice();
		}
		 */

		this.order.isCalculated = e.target.checked;
	};

	dispose = autorun((r) => {
		this.calculatePrice();
	});

	reaction2 = reaction(
		() => md5(JSON.stringify(this.order)),
		() => this.calculatePrice()
	);

	@action setContracts = contracts => this.contracts = contracts;

	@action setContract = e => {
		const id = parseInt(e.target.value);
		if (id === 0) {
			this.order.contract = this.defaultOrder.contract;
			this.getNumber();
		} else {
			const contract = this.contracts.find(c => c.id === id);
			if (contract === undefined) {
				this.order.contract = this.defaultOrder.contract;
			} else {
				this.order.contract = contract;
			}
		}
	};

	@action setContractType = e => {
		set(this.order.contract, 'type.id', e.target.value);
		this.getNumber();
	};

	@action setContractProperty = (field, value) => set(this.order.contract, field, value);

	@action getNumber = () => this.mainStore.ajax(`/api/contract/get_number/${this.order.contract.type.id}`, {}, data => set(this.order.contract, 'number', data.number));

	@action addOrderPhone = phone => {
		if (phone[0].replace(/\D+/g, '').length < 10) {
			this.mainStore.addError(`Некорректный номер телефона (меньше 10 цифр): ${phone[0]}`);
			return false;
		}

		this.order.phones.push(phone[0].replace(/\D+/g, '').replace(/^(.*)(\d{10})$/g, '$2'));
	};

	@action delOrderPhone = (phone, index) => this.order.phones.splice(index, 1);

	@action setCashier = e => this.order.cashier = {id: e.target.value};

	@action handleChangeOrder = e => {
		const item = JSON.parse(e.data);
		delete item["@context"];
		delete item["@id"];
		delete item["@type"];

		const index = this.orders.findIndex(i => i.id === item.id);

		if (index === -1) {
			this.orders.push(item);
		} else {
			this.orders[index] = item;
		}
	};
}

export default OrderStore;