import React from "react";
import {observable, action, computed} from 'mobx';
import EditRewardDialogBP from "../components/EditRewardDialogBP";

class RewardStore {
	terms = ['заказ', 'заказа', 'заказов'];
	onCreateButtonTitle = "Новый заказ";
	titleField = "id";
	headers = [
                {id: 'id', label: '№', width: 80},
		{id: 'fio', label: 'Исполнитель', width: 400, sort: i => i.fio.toLowerCase().trim()},
		{id: 'sum', label: 'Задолженность', func: price => new Intl.NumberFormat('ru-RU').format(price) + ' руб.', width: 200, sort: i => parseInt(i.sum)},
		{id: 'enabled', label: 'Статус', func: enabled => enabled == 1 ? "Активен" : "Заблокирован" , width: 200, sort: i => parseInt(i.sum)},
	];

	@observable items = [];
	@observable item = {};
	@observable ids = [];
	@observable isEditDialogOpen = false;
	@observable histories = [];

	constructor(rootStore) {
		this.mainStore = rootStore.mainStore;
	}

	@action fetch = () => {
		this.mainStore.ajax('/api/rewards', {}, data => {
			this.items = data.users;
		});
	};

	@action clear = () => {
		this.items = [];
		this.histories = [];
	};

	@action edit = id => {
		this.mainStore.ajax('/api/reward/' + id, {}, data => {
			this.item = data.orders[0];
			this.histories = data.histories;
			this.openEditDialog();
		});
	};

	@computed get editDialogTitle() {
		return '';
	};

	@computed get editDialog() {
		return <EditRewardDialogBP/>;
	}

	@action openEditDialog = () => this.isEditDialogOpen = true;
	@action closeEditDialog = () => {
		this.isEditDialogOpen = false;
		this.histories = [];
	};

	@action changeIds = (checked, id) => {
		if (checked) {
			this.ids.push(id);
		} else {
			const index = this.ids.indexOf(id);
			this.ids.splice(index, 1);
		}
	};

	@action closeRewards = () => {
		if (this.ids.length > 0) {
			this.mainStore.ajax('/api/rewards/close', {ids: this.ids}, data => {
				this.closeEditDialog();
				this.item = {};
				this.fetch();
			});
		} else {
			this.closeEditDialog();
		}
	};
}

export default RewardStore;