import React from 'react';
import {Omnibar} from "@blueprintjs/select";
import {MenuItem} from "@blueprintjs/core";
import {toJS} from "mobx";
import {inject, observer} from "mobx-react";
import get from 'lodash/get';

const SearchClient = inject('orderStore')(observer(({isSearchOpen = false, setIsSearchOpen = null, highlightText = null, orderStore, ...props}) => {
	const getProp = (object, name, defaultValue = '') => {
		const item = object;
		try {
			if (eval(`${name}`) === undefined) {
				return defaultValue;
			}
			return eval(`${name}`);
		} catch (e) {
			return defaultValue;
		}
	};

	const itemRenderer = (item, {handleClick, modifiers, query}) => {
		if (!modifiers.matchesPredicate) {
			return null;
		}

		let text = '';
		let label = '';
		let key = '';

		if (orderStore.isPeople) {
			text = item.name;
			key = item.id;
			label = null;
		} else {
			if (item.isNew) {
				text = `${item.value}, ИНН ${item.data.inn}`;
				label = '[создать]';
				// key = `${item.data.inn}:${item.data.kpp === null ? 0 : item.data.kpp}`;
				key = item.data.hid;
			} else {
				text = get(item, 'client.title', false) ? `${item.client.title} (${item.name}), ИНН ${item.inn}` : `${item.name}, ИНН ${item.inn}`;
				label = null;
				key = item.id;
			}
		}

		return (
			<MenuItem
				active={modifiers.active}
				disabled={modifiers.disabled}
				label={label}
				key={key}
				onClick={handleClick}
				text={highlightText(text, query)}
				multiline={true}
			/>
		);
	};

	const onItemSelect = item => {
		handleSelectItem(item);
		setIsSearchOpen(false);
	};

	const handleSelectItem = item => {
		if (orderStore.isPeople) {
			orderStore.order = {...orderStore.order, people: item, org: null};
			orderStore.suggestAddress1 = item.addresses;
			orderStore.setContracts(item.contracts);
		} else {
			if (item.isNew) {
				orderStore.order = {
					...orderStore.order, org: {
						id: 0,
						bankName: "",
						bik: "",
						checkAccount: "",
						corrAccount: "",
						emails: [],
						headName: getProp(item,'item.data.management.name'),
						headPosition: getProp(item, 'item.data.management.post'),
						inn: getProp(item, 'item.data.inn'),
						isActive: true,
						kpp: getProp(item, 'item.data.kpp'),
						legalAddress: getProp(item, 'item.data.address.data.value'),
						name: getProp(item, 'item.value'),
						ogrn: getProp(item, 'item.data.ogrn'),
						phones: [],
						client: {
							id: 0,
							title: getProp(item, 'item.value')
						},
						source: {
							id: 1
						}
					},
					people: null
				};
				orderStore.suggestAddress1 = [];
			} else {
				orderStore.order = {...orderStore.order, org: item, people: null};
				orderStore.suggestAddress1 = item.addresses;
				orderStore.setContracts(item.contracts);
				if (item.contracts.length > 0) {
					orderStore.order.withContract = true;
					orderStore.order.contract = item.contracts[0];
				}
			}
		}
	};

	const createNewItemRenderer = (query, active, handleClick) => (
		<MenuItem
			icon="add"
			text={`Новое физ.лицо "${query}"`}
			active={active}
			onClick={() => handleCreatePeople(query)}
			shouldDismissPopover={false}
		/>
	);

	const handleCreatePeople = name => {
		orderStore.order = {
			...orderStore.order, people: {
				emails: [],
				id: 0,
				isActive: true,
				name: name.trim(),
				phones: []
			}
		};
		setIsSearchOpen(false);
	};

	const createNewItemFromQuery = query => {
		return {id: 0, name: query};
	};

	return (
		<Omnibar isOpen={isSearchOpen}
		         items={orderStore.isPeople ? orderStore.suggestPeople : orderStore.suggestOrg}
		         itemRenderer={itemRenderer}
		         onItemSelect={onItemSelect}
		         onClose={() => setIsSearchOpen(false)}
		         inputProps={{placeholder: 'Введите имя клиента, юр.лица, ИНН, телефон или e-mail...'}}
		         onQueryChange={orderStore.findOrg}
		         createNewItemFromQuery={orderStore.isPeople ? createNewItemFromQuery : null}
		         createNewItemRenderer={createNewItemRenderer}
		         allowCreate={true}
		/>
	);
}));

export default SearchClient;