import {observable, action, computed} from 'mobx';
import EditPeopleDialogBP from "../components/EditPeopleDialogBP";
import React from "react";

class PeopleStore {
	defaultItem = {
		id: 0,
		name: '',
		phones: [],
		emails: [],
		addresses: [],
		source: {},
		manager: {},
	};
	terms = ['физ.лицо', 'физ.лица', 'физ.лиц'];
	onCreateButtonTitle = 'Новое физ.лицо';
	titleField = 'name';
	timer = null;

	headers = [
		{id: 'name', label: 'ФИО', width: 400, sort: i => i.name.toLowerCase().trim()},
		{id: 'phones', label: 'Телефоны', width: 200, func: phones => phones.map(p => p.number.replace(/\D+/mg, '').replace(/(\d{3})(\d{3})(\d{2})(\d{2})$/, '+7 ($1) $2-$3-$4')).join(', '), sort: i => i.phones.map(p => p.number.replace(/\D+/mg, '').replace(/(\d{3})(\d{3})(\d{2})(\d{2})$/, '+7 ($1) $2-$3-$4')).join(', ')},
		{id: 'emails', label: 'E-mail', width: 200, func: emails => emails.map(e => e.email).join(', '), sort: i => i.emails.map(e => e.email).join(', ').toLowerCase()},
		{id: 'manager.fio', label: 'Менеджер', sort: i => (i.manager !== null ? i.manager.fio : '').fio.toLowerCase().trim()},
		{id: 'source.title', label: 'Источник', sort: i => (i.source !== null ? i.source.title : '').toLowerCase().trim()},
		{id: i => i.creator !== null ? i.creator.fio : '', label: 'Создал', sort: i => (i.creator !== null ? i.creator.fio : '').toLowerCase().trim()},
	];

	@observable item = {};
	@observable items = [];
	@observable isEditDialogOpen = false;
	@observable address = '';
	get address() { return this.address; }
	set address(address) { this.address = address; }

	@observable suggestAddress = [];
	@observable sources = [];
	@observable managers = [];

	@computed get editDialogTitle() {
		return this.item.id === 0 ? 'Новое физ.лицо' : 'Изменение физ.лица';
	};

	@computed get editDialog() {
		return <EditPeopleDialogBP/>;
	}

	constructor(rootStore) {
		this.mainStore = rootStore.mainStore;
		this.authStore = rootStore.authStore;
		this.defaultItem.manager.id = this.authStore.user.id;
		this.item = this.defaultItem;
		this.defaultItem.creator = rootStore.authStore.user;
	}

	@action fetch = () => this.mainStore.ajax('/api/peoples', {}, data => {
		this.items = data.peoples;
		this.sources = data.sources;
		this.managers = data.managers;
	});

	@action clear = () => {
		clearTimeout(this.timer);
		this.items = [];
		this.item = this.defaultItem;
	};

	@action edit = id => this.mainStore.ajax('/api/people/' + id, {}, data => {
		this.item = data.people;
		this.isEditDialogOpen = true;
	});

	@action create = () => {
		this.item = this.defaultItem;
		this.isEditDialogOpen = true;
	};

	@action update = () => this.mainStore.ajax('/api/people/update/' + this.item.id, this.item, () => {
		this.isEditDialogOpen = false;
		this.fetch();
	});

	@action delete = id => this.mainStore.ajax('/api/people/delete/' + id, {}, () => this.fetch());

	@action set = (field, value) => {
		eval('this.item.' + field + ' = value');
	};

	@action addPhone = phone => {
		try {
			if (phone[0].replace(/\D+/g, '').length < 10) {
				return;
			}

			this.item.phones.push({
				id: 0,
				number: phone[0].replace('/\D+/g', '').replace(/^.*(\d{10})$/, '$1')
			});

			return true;
		} catch (e) {
			return false;
		}
	};

	@action removePhone = (phone, index) => {
		try {
			this.item.phones.splice(index, 1);
		} catch (e) {
			return false;
		}
	};

	@action openEditDialog = () => this.isEditDialogOpen = true;
	@action closeEditDialog = () => this.isEditDialogOpen = false;

	@action findAddress = needle => {
		clearTimeout(this.timer);
		if (needle.length >= 2) {
			this.timer = setTimeout(() => {
				const dadataToken = this.mainStore.dadataToken;
				this.mainStore.startLoading();
				fetch('https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address', {
					method: 'POST',
					mode: 'cors',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': 'Token ' + dadataToken
					},
					body: JSON.stringify({
						query: needle,
						count: 10,
						locations_boost: [
							{kladr_id: 77},
							//{kladr_id: 78},
							//{kladr_id: 47},
						]
					})
				})
					.then(res => res.json())
					.then(data => {
						this.suggestAddress = data.suggestions.map(s => {
							return {
								id: 0,
								address: s.value,
								locations: [],
							};
						});
					})
					.catch(alert)
					.finally(() => {
						this.mainStore.stopLoading();
					});
			}, 500);
		}
	};

	@action addAddress = () => this.item.addresses.push({id: 0, address: this.address, locations: []});

	@action removeAddress = index => this.item.addresses.splice(index, 1);
}

export default PeopleStore;