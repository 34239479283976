import AuthStore from './AuthStore';
import MainStore from './MainStore';
import OrgStore from './OrgStore';
import PeopleStore from './PeopleStore';
import UnitStore from './UnitStore';
import UserStore from './UserStore';
import ContractStore from './ContractStore';
import OrderStore from './OrderStore';
import CalendarStore from './CalendarStore';
import RewardStore from './RewardStore';
import ExecutorStore from './ExecutorStore';
import DefaultStore from './DefaultStore';
import AddressStore from "./AddressStore";
import MessagesStore from "./MessagesStore";

class RootStore {
	constructor() {
		this.mainStore = new MainStore(this);
		this.authStore = new AuthStore(this);
		this.mainStore = new MainStore(this);
		this.defaultStore = new DefaultStore(this);
		this.messagesStore = new MessagesStore(this);
		this.orgStore = new OrgStore(this);
		this.orgStore = new OrgStore(this);
		this.peopleStore = new PeopleStore(this);
		this.unitStore = new UnitStore(this);
		this.userStore = new UserStore(this);
		this.contractStore = new ContractStore(this);
		this.calendarStore = new CalendarStore(this);
		this.orderStore = new OrderStore(this);
		this.rewardStore = new RewardStore(this);
		this.executorStore = new ExecutorStore(this);
		this.addressStore = new AddressStore(this);
	}
}

export default new RootStore();