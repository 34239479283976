import React from 'react';
import {Select} from "@blueprintjs/select";
import {inject, observer} from "mobx-react";
import {Button, MenuItem} from "@blueprintjs/core";
import {toJS} from "mobx";
import get from 'lodash/get';
import highlightText from "../../utils/highlightText";

const SelectAddress = inject('orderStore')(observer(({orderStore, ...props}) => {
	const handleSelectAddress = a => {
		orderStore.set('address', a);
		orderStore.set('location', null);
		orderStore.locations = Boolean(toJS(a.locations)) ? a.locations : orderStore.locations = [];
	};

	return (
		<Select
			items={orderStore.suggestAddress}
			itemRenderer={(item, { handleClick, modifiers, query }) => {
				const temp = item.address + (get(item, 'shopNumber', '') !== '' ? ` (№0${item.shopNumber})` : '');

				/*
				if (query.length > 0 && temp.toLowerCase().search(query.toLowerCase()) === -1) {
					//return null;
				}
				 */

				if (query.length > 0 && highlightText(temp, query).length === 1) {
					return null;
				}

				return (
					<MenuItem
						active={modifiers.active}
						disabled={modifiers.disabled}
						label={item.id === 0 ? '[новый]' : null}
						key={item.id + item.address}
						onClick={handleClick}
						text={highlightText(temp, query)}
						multiline={true}
						style={{backgroundColor: item.id === 0 ? '#FFCC99' : '#66FF66'}}
					/>
				);
			}}
			onItemSelect={handleSelectAddress}
			inputProps={{placeholder: 'Введите адрес...', fill: true}}
			popoverProps={{fill: true, width: '100%'}}
			onQueryChange={orderStore.findAddress}
		>
			<Button
				text={get(orderStore.order, 'address.address', '')}
				// rightIcon="double-caret-vertical"
				fill={true}
				alignText='left'
			/>
		</Select>
	);
}));

export default SelectAddress;