import React from 'react';
import {inject, observer} from "mobx-react";
import {MultiSelect, Select} from "@blueprintjs/select";
import {Button, MenuItem} from "@blueprintjs/core";
import highlightText from "../../utils/highlightText";
import {toJS} from "mobx";

const SelectExecutors = inject('orderStore')(observer(({highlightText = null, orderStore, ...props}) => {
	const handleItemSelect = e => {
		const founded = orderStore.order.executors.findIndex(i => i.id === e.id);

		if (founded === -1) {
			orderStore.order.executors.push(e);
		} else {
			orderStore.order.executors.splice(founded, 1);
		}
	};

	const handleOnRemove = (s, i) => {
		orderStore.order.executors.splice(i, 1);
	};

	const executorItemPredicate = (query, item) => {
		if (query.length < 3) {
			return true;
		}

		const text = highlightText(`${item.fio}`, query);
		return text.length > 1;
	};

	return (
		<MultiSelect
			items={orderStore.executors}
			itemRenderer={(item, { handleClick, modifiers, query }) => (
				<MenuItem
					active={modifiers.active}
					disabled={modifiers.disabled}
					icon={orderStore.order.executors.find(e => e.id === item.id) ? "tick" : "blank"}
					key={item.id + item.fio}
					onClick={handleClick}
					text={highlightText(item.fio, query)}
					multiline={true}
				/>
			)}
			onItemSelect={handleItemSelect}
			inputProps={{placeholder: 'Исполнители...', fill: true}}
			popoverProps={{fill: true, width: '100%'}}
			tagRenderer={e => e.fio}
			itemPredicate={executorItemPredicate}
			selectedItems={orderStore.order.executors}
			tagInputProps={{onRemove: handleOnRemove}}
		/>
	);
}));

export default SelectExecutors;