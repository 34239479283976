import {observable, action} from 'mobx';
import set from "lodash/set";

class DefaultStore {
    @observable items = [];
    @observable item = {};
    @observable isEditDialogOpen = false;

    constructor(rootStore) {
        this.mainStore = rootStore.mainStore;
    }

    @action openEditDialog = () => this.isEditDialogOpen = true;
    @action closeEditDialog = () => this.isEditDialogOpen = false;
    @action set = (field, value) => set(this.item, field, value);
    onlyNeeded = obj => {
        Object.keys(obj).forEach(key => this.defaultItem[key] === undefined && delete obj[key]);
        return obj;
    };
}

export default DefaultStore;