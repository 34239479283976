import get from "lodash/get";
import {toJS} from "mobx";

export const defaultStore = {
    item: {},
    items: [],
    isEditDialogOpen: false,
    timestamp: 0,
    filters: {},
    page: 1,
    limit: localStorage.getItem('tablePerPage') === null ? 10 : parseInt(localStorage.getItem('tablePerPage')),
    pages: 0,
    sortBy: 0,
    sortDir: 'ASC',
    isDeleteDialogOpen: false,
    isItemsParsed: false,

    openEditDialog() {
        this.isEditDialogOpen = true;
    },

    closeEditDialog() {
        this.item = {};
        this.isEditDialogOpen = false;
    },

    clear() {
        this.items = [];
        this.item = {};
        this.resetFilters();
        this.setPage();
        this.pages = 0;
        this.sortBy = 0;
        this.sortDir = 'ASC';
    },

    updateTimestamp() {
        this.timestamp = new Date().getTime();
    },

    getHeaders() {
        const hdrs = this.headers.slice();
        if (hdrs[0].label !== '№') {
            hdrs.unshift({id: 'id', label: '№', width: 120});
        }
        return hdrs;
    },

    getItems() {
        let itms = this.items.slice();
        const headers = this.getHeaders();
        const sortBy = this.sortBy;
        const sortDir = this.sortDir === 'ASC' ? 1 : -1;
        const header = headers[this.sortBy];
        const filters = this.filters;

        headers.forEach((h, key) => {
            if (filters[key] !== undefined && filters[key].length >= 2) {
                itms = itms.filter(i => (i[key].value === undefined ? i[key] : i[key].value).toString().toLowerCase().indexOf(filters[key].toLowerCase()) !== -1);
            }
        });

        itms.sort((a, b) => {
            if (header.sort !== undefined && typeof header.sort === 'function') {
                if (a[sortBy].sort === b[sortBy].sort) return 0;
                return a[sortBy].sort > b[sortBy].sort ? sortDir : -sortDir;
            } else {
                if (a[sortBy] === b[sortBy]) return 0;
                return a[sortBy] > b[sortBy] ? sortDir : -sortDir;
            }
        });

        this.pages = Math.ceil(itms.length / this.limit);

        return itms = itms.slice((this.page - 1) * this.limit, (this.page - 1) * this.limit + this.limit);

        /*
        let itms = this.items.slice();

        const headers = this.getHeaders();

        headers.forEach((h, key) => {
            if (this.filters[key] === undefined || this.filters[key].length < 2) {
                return true;
            }

            itms = itms.filter(i => {
                let value;

                if (h.sort !== undefined && typeof h.sort === 'function') {
                    value = h.sort(i);
                } else if (typeof h.id === 'function') {
                    value = h.id(i);
                } else {
                    value = get(i, h.id, '');
                }

                try {
                    return value.toString().toLowerCase().indexOf(this.filters[key].toLowerCase())  !== -1
                } catch (e) {
                    return false;
                }
            });
        });

        this.pages = Math.ceil(itms.length / this.limit);

        const header = headers[this.sortBy];

        itms.sort((a, b) => {
            try {
                let fieldA;
                let fieldB;

                if (header.sort !== undefined && typeof header.sort === 'function') {
                    fieldA = header.sort(a);
                    fieldB = header.sort(b);
                } else if (typeof header.id === 'function') {
                    fieldA = header.id(a);
                    fieldB = header.id(b);
                } else {
                    fieldA = get(a, header.id, '');
                    fieldB = get(b, header.id, '');
                }

                if (typeof fieldA === 'string') {
                    fieldA = fieldA.trim().toLowerCase();
                }

                if (typeof fieldB === 'string') {
                    fieldB = fieldB.trim().toLowerCase();
                }

                if (fieldA === fieldB) return 0;

                return this.sortDir === 'ASC' ? (fieldA > fieldB ? 1 : -1) : (fieldA > fieldB ? -1 : 1);
            } catch (e) {
                return 0;
            }
        });

        return itms = itms.slice((this.page - 1) * this.limit, (this.page - 1) * this.limit + this.limit);
         */
    },

    resetFilters() {
        this.filters = {};
    },

    resetFilter(filter) {
        delete this.filters[filter];
    },

    setFilter(e) {
        this.filters[e.target.getAttribute('data-key')] = e.target.value;
    },

    setPage(page = 1) {
        this.page = parseInt(page);
    },

    setLimit(limit = false) {
        if (limit === false) {
            this.limit = localStorage.getItem('tablePerPage') === null ? 10 : parseInt(localStorage.getItem('tablePerPage'));
        } else {
            localStorage.setItem('tablePerPage', limit.toString());
            this.limit = limit;
        }
    },

    setSort(index) {
        if (index === this.sortBy) {
            this.sortDir = this.sortDir === 'ASC' ? 'DESC' : 'ASC';
        } else {
            this.sortBy = index;
            this.sortDir = 'ASC';
        }
    },

    openDeleteDialog(id) {
        this.item = {...this.items.slice().find(i => i[0] == id )};
        this.isDeleteDialogOpen = true;
    },

    closeDeleteDialog() {
        this.isDeleteDialogOpen = false;
        this.item = {};
    },

    parseItems(items) {
        const headers = this.getHeaders();

        return items.map(i => {
            return headers.map(h => {
                let value;

                if (h.func !== undefined && typeof h.func === 'function') {
                    value = h.func(i);
                } else if (typeof h.id === 'function') {
                    value = h.id(i);
                } else {
                    value = get(i, h.id, '');
                }

                if (h.id !== 'id') {
                    value = value.toString();
                }

                if (h.sort !== undefined && typeof h.sort === 'function') {
                    return {
                        value: value,
                        sort: h.sort(i)
                    }
                } else {
                    return value;
                }
            })
        })
    },
};